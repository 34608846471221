import React from 'react';
import SmoothScroll from 'smooth-scroll';

export const useHeightToggle = (open: boolean, initialHeight: number = 0) => {
  const ref = React.useRef<HTMLDivElement>();

  const [style, setStyle] = React.useState({
    height: initialHeight,
    overflow: 'hidden',
    transition: 'height 0.2s',
  });

  React.useEffect(() => {
    setStyle((prevStyle) => {
      if (open && prevStyle.height === 0 && ref.current) {
        return {
          ...prevStyle,
          height: ref.current.scrollHeight,
        };
      } else if (!open && prevStyle.height > 0) {
        return {
          ...prevStyle,
          height: 0,
        };
      }
      return prevStyle;
    });
  }, [open, ref]);

  return [ref, style];
};

// Maybe replace with https://github.com/dai-shi/react-hooks-fetch
/**
 *
 * @param fetchFn function to fetch data. Change (e.g. re-create the fetchFn
 * to retrigger fetch). Be careful not to dynamically create fetchFn on each
 * render as it'll trigger fetch for every render.
 *
 * @example
 *   // Create a memoized fetch function
 *   const fetchFn = React.useCallback(
 *     () => fetchSomething(args1, arg2, arg3),
 *     [arg1, arg2, arg3, fetchSomething],
 *   );
 *   const [data, loading] = useData<ReturnType>(fetchFn);
 */
export function useData<T>(
  fetchFn: () => Promise<T | undefined>,
): [T | undefined, boolean] {
  const [data, setData] = React.useState<T | undefined>();
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    fetchFn().then((newData) => {
      setData(newData);
      setLoading(false);
    });
  }, [fetchFn]);
  return [data, loading];
}

/**
 * Simplified useData that sets up memoized callback
 */
export function useDataSimple<A, T>(fn: (...fnArgs: A[]) => Promise<T>, ...fnArgs: A[]) {
  const fetchFn = React.useCallback(
    () => fn(...fnArgs),
    // Using spread which causes eslint warning for react hooks.
    // Disable because probably ok
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fn, ...fnArgs],
  );
  return useData(fetchFn);
}

export function useWindowWidth() {
  const [windowWidth, setWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    const onResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', onResize);
    return function cleanup() {
      window.removeEventListener('resize', onResize);
    };
  }, []); // use [] so effect on runs only on mount and unmount and not every update
  return windowWidth;
}

export function useScrollToTop() {
  React.useEffect(() => new SmoothScroll().animateScroll(0), []);
}
