import { Action, combineReducers } from 'redux';
import { ThunkAction } from 'redux-thunk';

import hyvorGuestLogin from './hyvorGuestLogin/reducers';
import organizationsByType from './organizations/reducers';
import contentByPlayerId from './playerContent/reducers';
import players from './players/reducers';
import playersGraphData from './playersGraphData/reducers';
import playersSpotlights from './playersSpotlights/reducers';
import playersStats from './playersStats/reducers';
import playlists from './playlists/reducers';
import searchResultsByKeyword from './searchResultsByKeyword/reducers';
import user from './user/reducers';
import videosById from './videosById/reducers';
import videosByPlayerId from './videosByPlayerId/reducers';
import videosByPlaylistId from './videosByPlaylistId/reducers';

const rootReducer = combineReducers({
  contentByPlayerId,
  hyvorGuestLogin,
  organizationsByType,
  players,
  playersGraphData,
  playersSpotlights,
  playersStats,
  playlists,
  searchResultsByKeyword,
  user,
  videosById,
  videosByPlayerId,
  videosByPlaylistId,
});

export type AppState = ReturnType<typeof rootReducer>;

/**
 * Like a ThunkAction but return type is a Promise and uses our state and string actions
 */
export type NextOnesAction<TReturnType> = ThunkAction<Promise<TReturnType>, AppState, null, Action<string>>;

export default rootReducer;
