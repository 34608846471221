import { ICommon } from '../types';

export interface IGraphData {
  performancehistory: {
    label: string;
    data: Array<{
      date: string;
      value: number;
      opp_team_short: string;
    }>;
  };
  gamestats: Array<{
    label: string
    data: Array<{
      date: string;
      value: number;
    }>;
  }>;
}

export interface IAppStatePlayersGraphData extends ICommon {
  data: {
    [slug: string]: IGraphData,
  };
}

export const ACTION_PLAYERS_GRAPH_DATA_PROCESSING = 'ACTION_PLAYERS_GRAPH_DATA_PROCESSING';
export const ACTION_PLAYERS_GRAPH_DATA_COMPLETE = 'ACTION_PLAYERS_GRAPH_DATA_COMPLETE';
export const ACTION_PLAYERS_GRAPH_DATA_FAILED = 'ACTION_PLAYERS_GRAPH_DATA_FAILED';

interface IPlayersGraphDataActionProcessing {
  type: typeof ACTION_PLAYERS_GRAPH_DATA_PROCESSING;
}
interface IPlayersGraphDataActionComplete {
  type: typeof ACTION_PLAYERS_GRAPH_DATA_COMPLETE;
  data: IGraphData;
  slug: string;
}
interface IPlayersGraphDataActionFailed {
  type: typeof ACTION_PLAYERS_GRAPH_DATA_FAILED;
}

export type PlayersGraphDataAction =
  IPlayersGraphDataActionProcessing |
  IPlayersGraphDataActionComplete |
  IPlayersGraphDataActionFailed;
