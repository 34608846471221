import { IContentArticleItem, IContentVideoItem } from '../creatorContent/types';
import { IsFetching } from '../types';

export interface IAppStateContentByPlayerId {
  [id: string]: {
    data?: Array<IContentArticleItem | IContentVideoItem>,
    fetchedAt?: Date,
    rowCount?: number,
    isFetching?: IsFetching,
  };
}

export const ACTION_CONTENT_BY_PLAYER_ID_CLEAR = 'ACTION_CONTENT_BY_PLAYER_ID_CLEAR';
export const ACTION_CONTENT_BY_PLAYER_ID_PROCESSING = 'ACTION_CONTENT_BY_PLAYER_ID_PROCESSING';
export const ACTION_CONTENT_BY_PLAYER_ID_COMPLETE = 'ACTION_CONTENT_BY_PLAYER_ID_COMPLETE';
export const ACTION_CONTENT_BY_PLAYER_ID_FAILED = 'ACTION_CONTENT_BY_PLAYER_ID_FAILED';

interface IContentByPlayerIdActionProcessing {
  type: typeof ACTION_CONTENT_BY_PLAYER_ID_PROCESSING;
  rowCount: number;
  id: string;
  data: Array<IContentArticleItem | IContentVideoItem>;
}
interface IContentByPlayerIdActionClear {
  type: typeof ACTION_CONTENT_BY_PLAYER_ID_CLEAR;
  rowCount: number;
  id: string;
  data: Array<IContentArticleItem | IContentVideoItem>;
}
interface IContentByPlayerIdActionComplete {
  type: typeof ACTION_CONTENT_BY_PLAYER_ID_COMPLETE;
  rowCount: number;
  id: string;
  data: Array<IContentArticleItem | IContentVideoItem>;
}
interface IContentByPlayerIdActionFailed {
  type: typeof ACTION_CONTENT_BY_PLAYER_ID_FAILED;
  rowCount: number;
  id: string;
  data: Array<IContentArticleItem | IContentVideoItem>;
}
export type ContentByPlayerIdAction =
  IContentByPlayerIdActionProcessing |
  IContentByPlayerIdActionComplete |
  IContentByPlayerIdActionFailed |
  IContentByPlayerIdActionClear;
