import { IsFetching } from '../types';

export interface IVideoData {
  channellogo: string | null;
  description: string;
  hashtags: string;
  hashtags_clean: string;
  id: string;
  playlistid: string;
  publishedat: string;
  title: string;
  videoid: string;
  type: string | null;
  nextones_like_count: number;
}
export interface IAppStateVideosById {
  [id: string]: {
    data?: IVideoData[],
    fetchedAt?: Date,
    isFetching?: IsFetching,
  };
}

export const ACTION_VIDEOS_BY_ID_PROCESSING = 'ACTION_VIDEOS_BY_ID_PROCESSING';
export const ACTION_VIDEOS_BY_ID_COMPLETE = 'ACTION_VIDEOS_BY_ID_COMPLETE';
export const ACTION_VIDEOS_BY_ID_FAILED = 'ACTION_VIDEOS_BY_ID_FAILED';

interface IVideosByIdActionProcessing {
  type: typeof ACTION_VIDEOS_BY_ID_PROCESSING;
  id: string;
  data?: IVideoData[];
}
interface IVideosByIdActionComplete {
  type: typeof ACTION_VIDEOS_BY_ID_COMPLETE;
  id: string;
  data?: IVideoData[];
}
interface IVideosByIdActionFailed {
  type: typeof ACTION_VIDEOS_BY_ID_FAILED;
  id: string;
  data?: IVideoData[];
}

export type VideosByIdAction =
  IVideosByIdActionProcessing |
  IVideosByIdActionComplete |
  IVideosByIdActionFailed;
