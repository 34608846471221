const {
  REACT_APP_FUNCTIONS_API_URL = 'https://us-central1-samico-nextones.cloudfunctions.net',
} = process.env;

const BASE_URL = `${REACT_APP_FUNCTIONS_API_URL}/content`;

export const API_VIDEOS_URL = `${BASE_URL}/videos`;
export const API_FEATURED_PLAYLISTS_URL = `${BASE_URL}/getAllFeaturedPlaylists`;
export const API_KEYWORD_SEARCH_URL = `${BASE_URL}/searchByKeyword`;
export const API_SEARCH_FOR_PLAYERS_URL = `${BASE_URL}/searchForPlayers`;
export const API_VIDEOS_BY_PLAYER_ID_URL = `${BASE_URL}/videosByPlayerId`;
export const API_PLAYER_SCOUTING_CONTENT_URL = `${BASE_URL}/playerScoutingContent`;
export const API_PLAYER_STAT_TOTALS_URL = `${BASE_URL}/playerStatsTotals`;
export const API_PLAYERS_BY_VIDEO_ID_URL = `${BASE_URL}/playersByVideoId`;
export const API_CURRENT_PLAYERS_SPOTLIGHTS_URL = `${BASE_URL}/getCurrentPlayerSpotlight`;
export const API_DRAFT_PICKS = `${BASE_URL}/getDraftPicks`;
export const API_GEAR_PRODUCTS_URL = `${BASE_URL}/gearProducts`;
export const API_HYVOR_GUEST_URL = `${BASE_URL}/hyvorGuestLogin`;

const PLAYER_BASE_URL = `${REACT_APP_FUNCTIONS_API_URL}/contentPlayer`;
export const API_USER_VIDEOS_URL = `${PLAYER_BASE_URL}/videos`;
export const API_USER_VIDEO_ANALYTICS_URL = `${PLAYER_BASE_URL}/video-analytics`;
export const API_USER_ARTICLES_URL = `${PLAYER_BASE_URL}/articles`;
export const API_USER_POSTS_URL = `${PLAYER_BASE_URL}/user-posts`;
export const API_USER_ME_URL = `${PLAYER_BASE_URL}/me`;
export const API_USER_FOLLOWS_URL = `${API_USER_ME_URL}/userFollows`;
export const API_USER_FOLLOWS_BY_SLUG_URL = `${API_USER_ME_URL}/userFollowsBySlug`;
export const API_USER_PLAYER_SIGNUP_URL = `${PLAYER_BASE_URL}/signupWithEmailAndInstagram`;
export const API_USER_CREATOR_SIGNUP_URL = `${PLAYER_BASE_URL}/signupCreator`;
export const API_USER_PLAYER_COMPLETE_SIGNUP_URL = `${PLAYER_BASE_URL}/completeSignup`;
export const API_LIKES_URL = `${API_USER_ME_URL}/likes`;
export const API_LIKES_BY_ID_URL = `${API_USER_ME_URL}/likesById`;

export const API_PLAYERS = `${BASE_URL}/players`;
export const API_ORGANIZATIONS = `${BASE_URL}/organizations`;
export const API_VIDEOS_V2_URL = `${BASE_URL}/videos_v2`;
export const API_USER_FOLLOWED_VIDEOS_URL = `${BASE_URL}/videos_v2/me`;

export const API_CREATORS = `${BASE_URL}/creators`;
export const API_LEAGUE_STATS_BY_INTERVAL = `${BASE_URL}/leagueStatTotalsByInterval`;

export const API_ARTICLES = `${BASE_URL}/articles`;
export const API_CREATOR_CONTENT = `${BASE_URL}/creatorContent`;
export const API_FOLLOWED_CREATOR_CONTENT_URL = `${API_CREATOR_CONTENT}/me`;
