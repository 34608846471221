import {
  ACTION_PLAYERS_GRAPH_DATA_COMPLETE,
  ACTION_PLAYERS_GRAPH_DATA_FAILED,
  ACTION_PLAYERS_GRAPH_DATA_PROCESSING,
  IAppStatePlayersGraphData,
  PlayersGraphDataAction,
} from './types';

const players = (
  state: IAppStatePlayersGraphData = { data: {} },
  action: PlayersGraphDataAction,
): IAppStatePlayersGraphData => {
  switch (action.type) {
    case ACTION_PLAYERS_GRAPH_DATA_PROCESSING:
      return {
        ...state,
        isFetching: new Date(),
      };
    case ACTION_PLAYERS_GRAPH_DATA_COMPLETE:
      return {
        data: {
          ...state.data,
          [action.slug]: action.data,
        },
        fetchedAt: new Date(),
        isFetching: false,
      };
    case ACTION_PLAYERS_GRAPH_DATA_FAILED:
      return {
        ...state,
        fetchedAt: new Date(),
        isFetching: false,
      };
    default:
      return state;
  }
};

export default players;
