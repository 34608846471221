import * as firebase from 'firebase/app';
import { fetchByUrlWithAuth } from '../common';
import { NextOnesAction } from '../index';
import {
  API_USER_CREATOR_SIGNUP_URL,
  API_USER_ME_URL,
  API_USER_PLAYER_COMPLETE_SIGNUP_URL,
} from '../urls';
import {
  API_USER_PLAYER_SIGNUP_URL,
} from '../urls';
import {
  ACTION_USER_LOADING,
  ACTION_USER_SET,
  IUser,
  IUserMeApiResponse,
} from './types';

export const logIn = (email: string, password: string): NextOnesAction<void> => async (dispatch) => {
  dispatch({ type: ACTION_USER_LOADING });
  await firebase.auth().signInWithEmailAndPassword(email, password);
};

export const setSignedInUser = (user: firebase.User | null): NextOnesAction<void> => async (dispatch) => {
  if (!user) {
    dispatch({ type: ACTION_USER_SET, data: null });
    return;
  }

  // Get user data from nextones backend. May include minimum player data
  // and HyvorTalk SSO object
  const { data: me } = await fetchByUrlWithAuth<IUserMeApiResponse>(API_USER_ME_URL);

  const data: IUser = {
    ...me,
    ...user,
  };

  dispatch({ type: ACTION_USER_SET, data });
};

export const resetPassword = (email: string): NextOnesAction<void> => async () => {
  await firebase.auth().sendPasswordResetEmail(email);
};

export const confirmPasswordReset = (code: string, newPassword: string): NextOnesAction<void> => async () => {
  await firebase.auth().confirmPasswordReset(code, newPassword);
};

export const signUpPlayer = (email: string, instagram: string) => async () => {
  const response = await fetch(
    `${API_USER_PLAYER_SIGNUP_URL}`,
    {
      body: JSON.stringify({ email, instagram }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    },
  );
  return response;
};

export const signUpCreator = (email: string, youtube: string) => async () => {
  const response = await fetch(
    `${API_USER_CREATOR_SIGNUP_URL}`,
    {
      body: JSON.stringify({ email, youtube }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    },
  );
  return response;
};

export const completeSignupPlayer = (password: string, signupToken?: string | null) => async () => {
  const response = await fetch(
    `${API_USER_PLAYER_COMPLETE_SIGNUP_URL}`,
    {
      body: JSON.stringify({ password }),
      headers: {
        'Authorization': `Bearer ${signupToken}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    },
  );
  return response;
};
