import React, { CSSProperties } from 'react';
import missingPlayer from '../../assets/images/missing_player.png';

import styles from './PlayerImage.module.css';

interface IRoundPlayerImageWithBorderProps {
  className?: string;
  style?: CSSProperties;
  src?: string | null;
  loading?: boolean;
  isLogo?: boolean;
}
export const RoundPlayerImageWithBorder = ({
  style,
  className,
  src,
  loading,
  isLogo,
}: IRoundPlayerImageWithBorderProps) => (
  <div style={style} className={[styles.imageBorder, className || ''].join(' ')}>
    {loading ? null : (
      <div
        className={[
          styles.image,
          isLogo ? styles.logoImg : styles.playerImg,
        ].join(' ')}
        style={{
          backgroundImage: `url(${src ? src : missingPlayer})`,
        }}
      />
    )}
  </div>
);

interface IPlayerImageProps {
  imageuri?: string | null;
  name?: string;
  position?: string | null;
  school?: string | null;
}
const PlayerImage = ({
  imageuri,
  name,
  position,
  school,
}: IPlayerImageProps) => {
  return (
    <div className={styles.container}>
      <RoundPlayerImageWithBorder src={imageuri ? imageuri : missingPlayer} />
      <div className={styles.detailsSect}>
        <div className={styles.boldText}>{name}</div>
        <div className={styles.greyText}>{position}</div>
        <div className={styles.greyText}>{school}</div>
      </div>
    </div>
  );
};

export default PlayerImage;
