import {
  ACTION_HYVOR_GUEST_COMPLETE,
  ACTION_HYVOR_GUEST_FAILED,
  ACTION_HYVOR_GUEST_PROCESSING,
  IAppStateHyvorGuestLogin,
  PlaylistsAction,
} from './types';

const hyvorGuestLogin = (
  state: IAppStateHyvorGuestLogin = { data: undefined },
  action: PlaylistsAction,
): IAppStateHyvorGuestLogin => {
  const {
    type,
    data,
  } = action;
  switch (type) {
    case ACTION_HYVOR_GUEST_PROCESSING:
      return {
        ...state,
        isFetching: new Date(),
      };
    case ACTION_HYVOR_GUEST_COMPLETE:
      return {
        ...state,
        data,
        fetchedAt: new Date(),
        isFetching: false,
      };
    case ACTION_HYVOR_GUEST_FAILED:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default hyvorGuestLogin;
