import get from 'lodash/get';

import { IVideoData } from '../videosById/types';
import {
  ACTION_VIDEOS_BY_PLAYLISTID_COMPLETE,
  ACTION_VIDEOS_BY_PLAYLISTID_FAILED,
  ACTION_VIDEOS_BY_PLAYLISTID_PROCESSING,
  IAppStateVideosByPlaylistId,
  VideosByPlayListIdAction,
} from './types';

const videosByPlaylistId = (
  state: IAppStateVideosByPlaylistId = {},
  action: VideosByPlayListIdAction,
): IAppStateVideosByPlaylistId => {
  const {
    type,
    id,
    data = [],
    rowCount,
  } = action;
  const stateData = get(state, `[${id}].data`, []);
  switch (type) {
    case ACTION_VIDEOS_BY_PLAYLISTID_PROCESSING:
      return {
        ...state,
        [id]: {
          data: stateData as IVideoData[],
          isFetching: new Date(),
          rowCount,
        },
      };
    case ACTION_VIDEOS_BY_PLAYLISTID_COMPLETE:
      return {
        ...state,
        [id]: {
          data: [
            ...stateData as IVideoData[],
            ...data,
          ],
          fetchedAt: new Date(),
          isFetching: false,
          rowCount,
        },
      };
    case ACTION_VIDEOS_BY_PLAYLISTID_FAILED:
      return {
        ...state,
        [id]: {
          isFetching: false,
        },
      };
    default:
      return state;
  }
};

export default videosByPlaylistId;
