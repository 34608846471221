import {
  faFacebook,
  faInstagram,
  faTwitch,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom';
import footerLogo from './footer-logo.png';
import sportradarLogo from './sports-radar_logo.png';

import COLORS from '../../Styles/colors';

import sharedStyles from '../../common/shared.module.css';
import styles from './Footer.module.css';

const {
  REACT_APP_CONTACT_EMAIL,
} = process.env;

interface ISocialIconProps {
  to: string;
  isMobile?: boolean;
  icon: any;
  label?: string;
}

const SocialIcon = ({ to, icon, label, isMobile }: ISocialIconProps) => (
  <a rel="noopener noreferrer" target="_blank" href={to} aria-label={`Next Ones on ${label}`}>
    <FontAwesomeIcon
      style={{
        color: COLORS.PALE_GREY,
        fontSize: isMobile ? '18px' : '32px',
        marginRight: 10,
        marginTop: 7,
      }}
      className={styles.addPlayerIcon}
      icon={icon}
      size="2x"
    />
  </a>
);

export const Footer = () => {
  const icons = [
    {
      icon: faYoutube,
      label: 'Youtube',
      to: 'https://www.youtube.com/channel/UC7yC7UlWPigYzNaJ9iBGB3A/featured',
    },
    {
      icon: faInstagram,
      label: 'Instagram',
      to: 'https://www.instagram.com/nextonesnetwork/',
    },
    {
      icon: faTwitter,
      label: 'Twitter',
      to: 'https://twitter.com/NXT1S',
    },
    {
      icon: faFacebook,
      label: 'Facebook',
      to: 'https://www.facebook.com/NextOnes-241118403110283',
    },
    {
      icon: faTwitch,
      label: 'Twitch',
      to: 'https://www.twitch.tv/nextonesnetwork',
    },
    {
      icon: faEnvelope,
      label: 'Email',
      to: `mailto:${REACT_APP_CONTACT_EMAIL}`,
    },
  ];

  return (
    <div className={`${sharedStyles.main} ${styles.main}`}>
      <div className={styles.footer}>
        <div className={styles.footerTop}>
          <MediaQuery minWidth={850}>
            <img src={footerLogo} alt="NextOnes Logo" height={60} />
          </MediaQuery>
          <div className={styles.footerLinks}>
            <Link className={styles.link} to="/privacy-policy">Privacy Policy</Link>
            <Link className={styles.link} to="/terms-of-use">Terms of Use</Link>
            <Link className={styles.link} to="/login">Creator Portal</Link>
          </div>
          <div className={styles.socialIcons}>
            {icons.map((icon, index) => (
              <SocialIcon key={`icon${index}`} {...icon} />
            ))}
          </div>
        </div>
        <a
          className={styles.sportradar}
          href="https://www.sportradar.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={sportradarLogo} alt="real-time score and stats provided by sportradar" />
        </a>
        <span className={styles.smallText}>
          {`Content Copyright Next Ones LLC, ${new Date().getFullYear()}`}
        </span>
      </div>
    </div>
  );
};

export default Footer;
