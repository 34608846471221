import { faBars, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import MediaQuery from 'react-responsive';
import { Link, RouteComponentProps } from 'react-router-dom';
import { IUser } from '../../store/user/types';
import logo from './logo.png';
import Search from './Search';

import sharedStyles from '../../common/shared.module.css';
import styles from './StandardHeader.module.css';

export interface ITab {
  id: number;
  label: string;
  to: string;
}

interface INavTabProps extends ITab {
  isActive: boolean;
  isMobile?: boolean;
  onClick?: () => void;
}
const NavTab = ({ label, isActive, to, isMobile, onClick }: INavTabProps) => (
  <Link
    to={to}
    className={[styles.navTab, isMobile && styles.mobileNavTab, isActive && styles.activeNavTab].join(' ')}
    onClick={onClick}
  >
    {label}
    { isMobile && (
      <FontAwesomeIcon
        icon={faChevronRight}
      />
    )}
  </Link>
);

export interface IHeaderProps extends Partial<RouteComponentProps> {
  activeTab?: number;
  searchenabled?: boolean;
  user?: IUser | null;
}

export interface IHeaderCompProps extends IHeaderProps {
  toggleInput: () => void;
  showInput: boolean;
  showDrawer: boolean;
  toggleDrawer: () => void;
  tabs: ITab[];
  legalTabs: ITab[];
  heroStyle?: boolean;
}

const DesktopHeader = ({
  tabs,
  activeTab,
  searchenabled,
  toggleInput,
  showInput,
  user,
}: IHeaderCompProps) => {
  return (
    <div className={`${sharedStyles.main} ${styles.fullHeader}`}>
      {!user ? <Link className={styles.topLink} to="/sign-up">Log In</Link>
      : (
        <div className={styles.loggedInGroup}>
          <Link className={styles.topLink} to="/logout">Log Out</Link>
          <Link className={styles.topLink} to="/profile">Manage Account</Link>
          {user.slug && (
            <Link className={styles.topLink} to={user.player && user.player.slug ? `/player/${user.player.slug}` : `/creator/${user.slug}`}>
              Profile
            </Link>
          )}
        </div>
      )}
      <div className={styles.main}>
        <Link
          style={{ height: '50px', display: 'flex', textDecoration: 'none' }}
          to="/"
        >
          <img src={logo} style={{ paddingTop: 12, height: '52%' }} alt="NextOnes Logo" />
          <div className={styles.nextOnesTitle}>
            <span style={{ marginRight: '4px'}}>
              Next
            </span>
            Ones
          </div>
        </Link>
        <div className={[sharedStyles.main, styles.navBar, showInput && styles.inputVisible].join(' ')}>
          {tabs.map((tab) => (
            <NavTab key={tab.label} id={tab.id} isActive={activeTab === tab.id} label={tab.label} to={tab.to} />
          ))}
        </div>

          <Search
            toggleInput={toggleInput}
            showInput
          />

      </div>
    </div>
  );
};

export const MobileHeader = React.forwardRef<HTMLDivElement, IHeaderCompProps>((
  {
    toggleInput,
    user,
    showInput,
    searchenabled,
    tabs,
    activeTab,
    showDrawer,
    toggleDrawer,
    heroStyle = false,
    legalTabs,
  },
  ref,
) => (
  <div ref={ref}>
    <div className={heroStyle ? showDrawer ? styles.mobileLogoHeroOpen : styles.mobileLogoHero : styles.mobileLogo}>
      {!heroStyle && (
        <Link
          to="/"
        >
          <img src={logo} style={{ marginRight: 10, height: 24, width: 20 }} alt="NextOnes Logo" />
        </Link>
      )}
      <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
        {
          searchenabled && !showDrawer && (
            <Search
              toggleInput={toggleInput}
              showInput={showInput}
              isMobile
              heroStyle={heroStyle}
            />
          )
        }
        {
          !showInput && (
            <div
              style={{
                alignSelf: showInput ? 'flex-end' : '',
                display: 'flex',
                justifyContent: 'space-between',
                marginLeft: '20px',
              }}
              onClick={toggleDrawer}
            >
              <FontAwesomeIcon
                className={styles.mobileBarsIcon}
                icon={faBars}
                style={{ color: heroStyle ? showDrawer ? '' : '#fff' : ''}}
              />
            </div>
          )
        }
      </div>
    </div>
    {
      showDrawer && (
        <div
          className={heroStyle ? styles.mobileDrawerContainerHero : styles.mobileDrawerContainer}
        >
          <div className={styles.mobileNavTabContainer}>
            {tabs.map((tab) => (
              <NavTab
                isMobile
                key={tab.label}
                id={tab.id}
                isActive={activeTab === tab.id}
                label={tab.label}
                to={tab.to}
                onClick={toggleDrawer}
              />
            ))}
            <div style={{ width: '100%', borderBottom: '1px solid #c9c8c8', margin: '10px 0px' }} />
            {
              legalTabs.map((tab) => (
                <NavTab
                  isMobile
                  key={tab.label}
                  id={tab.id}
                  isActive={activeTab === tab.id}
                  label={tab.label}
                  to={tab.to}
                  onClick={toggleDrawer}
                />
            ))}
            <div style={{ width: '100%', borderBottom: '1px solid #c9c8c8', margin: '10px 0px' }} />
            {!!user && (
              <>
                <NavTab
                  isMobile
                  key="tabManage"
                  id={98}
                  isActive={false}
                  label="Manage Account"
                  to="/profile"
                  onClick={toggleDrawer}
                />
                {user.slug && (
                  <NavTab
                    isMobile
                    key="tabProfile"
                    id={99}
                    isActive={false}
                    label="Profile"
                    to={user.player && user.player.slug ? `/player/${user.player.slug}` : `/creator/${user.slug}`}
                    onClick={toggleDrawer}
                  />
                )}
              </>)}
            <NavTab
              isMobile
              key="tabLogout"
              id={100}
              isActive={false}
              label={user ? 'Log Out' : 'Log In'}
              to={user ? '/logout' : '/sign-up'}
              onClick={toggleDrawer}
            />
          </div>
        </div>
      )
    }
  </div>
));

const StandardHeader = React.forwardRef<HTMLDivElement, IHeaderCompProps>((props, ref) => {
  return (
    <>
      <MediaQuery minWidth={850}>
        <DesktopHeader {...props} />
      </MediaQuery>
      <MediaQuery maxWidth={849}>
        <MobileHeader {...props} ref={ref} />
      </MediaQuery>
    </>
  );
});

export default StandardHeader;
