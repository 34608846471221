import { IPlayerData } from '../players/types';
import {
  ICommon,
} from '../types';

export interface IPlayersSpotlightsData {
  title: string;
  subtitle: string | null;
  players: IPlayerData[];
}
export interface IAppStatePlayersSpotlights extends ICommon {
  data: IPlayersSpotlightsData[];
}

export const ACTION_PLAYERS_SPOTLIGHTS_PROCESSING = 'ACTION_PLAYERS_SPOTLIGHTS_PROCESSING';
export const ACTION_PLAYERS_SPOTLIGHTS_COMPLETE = 'ACTION_PLAYERS_SPOTLIGHTS_COMPLETE';
export const ACTION_PLAYERS_SPOTLIGHTS_FAILED = 'ACTION_PLAYERS_SPOTLIGHTS_FAILED';

interface IPlayersSpotlightsActionProcessing {
  type: typeof ACTION_PLAYERS_SPOTLIGHTS_PROCESSING;
  data: IPlayersSpotlightsData[];
}
interface IPlayersSpotlightsActionComplete {
  type: typeof ACTION_PLAYERS_SPOTLIGHTS_COMPLETE;
  data: IPlayersSpotlightsData[];
}
interface IPlayersSpotlightsActionFailed {
  type: typeof ACTION_PLAYERS_SPOTLIGHTS_FAILED;
  data: IPlayersSpotlightsData[];
}

export type PlayersSpotlightsAction =
  IPlayersSpotlightsActionProcessing |
  IPlayersSpotlightsActionComplete |
  IPlayersSpotlightsActionFailed;
