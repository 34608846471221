import {
  faSearch, faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styles from './Search.module.css';

const OPEN_ICON_COLOR = '#737373';

export interface ISearchProps extends RouteComponentProps {
  showInput: boolean;
  toggleInput: () => void;
  isMobile?: boolean;
  iconColor?: string;
  heroStyle?: boolean;
}

export class Search extends React.Component<ISearchProps> {
  public selfRef = React.createRef<HTMLDivElement>();
  public inputRef = React.createRef<HTMLInputElement>();

  public componentDidMount() {
    window.addEventListener('click', this.onWindowClick);
    this.setInputValueLocationPath();
  }

  public componentDidUpdate(prevProps: ISearchProps) {
    if (!prevProps.showInput && this.props.showInput) {
      const node = this.inputRef.current;
      if (node) {
        node.focus();
      }
    }
    if (prevProps.location !== this.props.location) {
      this.setInputValueLocationPath();
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
  }

  public onWindowClick = (e: any) => {
    if (this.props.showInput) {
      // Close input if is outside this component
      let el = e.target;
      while (el) {
        if (el === this.selfRef.current) {
          // Ignore click
          return;
        }
        el = el.parentElement;
      }
      if (this.props.showInput) {
        const node = this.inputRef.current;
        if (node) {
          node.blur();
        }
      }
      this.props.toggleInput();
    }
  }

  public onKeyPress = (event: any) => {
    const {
      history,
    } = this.props;
    if (event.key === 'Enter') {
      event.preventDefault();
      if (event.target.value) {
        if (this.inputRef.current) {
          // Remove focus from input so soft keyword closes
          this.inputRef.current.blur();
        }
        history.push(`/search/${event.target.value}`);
      }
    }
  }

  public render() {
    const {
      isMobile = false,
      iconColor = OPEN_ICON_COLOR,
      showInput,
      toggleInput,
      heroStyle = false,
    } = this.props;

    let searchColor = '';

    if (heroStyle && !showInput) {
      searchColor = '#fff';
    } else if (!heroStyle && showInput) {
      searchColor = OPEN_ICON_COLOR;
    } else if (!heroStyle && !showInput) {
      searchColor = iconColor;
    }

    return (
      <div
        data-testid="Search"
        className={[
          styles.searchRow,
          (!showInput && styles.searchRowShowInputHidden) || '',
          (isMobile && styles.searchRowMobile) || '',
        ].join(' ')}
        ref={this.selfRef}
      >
        { isMobile && showInput && (
          <FontAwesomeIcon
            icon={faSearch}
            style={{
              color: heroStyle ? '#fff' : OPEN_ICON_COLOR,
              marginRight: heroStyle ? '10px' : '',
            }}
          />
        )}
        <button
          aria-label="Toggle Search"
          className={styles.searchIconButton}
          onClick={toggleInput}
          style={{
            right: !isMobile ? '8px' : '0',
          }}
        >
          <FontAwesomeIcon
            data-testid="SearchIcon"
            className={styles.searchIcon}
            style={{
              color: searchColor,
              marginRight: heroStyle && showInput ? '10px' : '',
            }}
            icon={(showInput && isMobile) ? faTimes : faSearch}
          />
        </button>
        <input
          aria-label="Search"
          ref={this.inputRef}
          className={styles.searchInput}
          type="text"
          onKeyPress={this.onKeyPress}
          placeholder="Search"
          style={{
            padding: heroStyle && showInput ? '8px 5px' : '',
          }}
        />
      </div>
    );
  }

  protected setInputValueLocationPath = () => {
    if (this.inputRef.current) {
      const match = this.props.location.pathname.match(/\/search\/(.+)/);
      this.inputRef.current.value = (match && match[1]) || '';
    }
  }
}

export default withRouter(Search);
