import React from 'react';
import { Link } from 'react-router-dom';
import { INextOnesDraftPick } from '../../store/types';
import { RoundPlayerImageWithBorder } from '../PlayerImage/PlayerImage';

import styles from './CompactDraftPickCard.module.css';

interface IDraftPickCard {
  loading: boolean;
  player?: INextOnesDraftPick;
}

const CompactDraftPickCard = ({
  loading,
  player,
}: IDraftPickCard) => {
  const {
    draftposition,
    imageuri,
    name,
    teamfullname,
    slug,
  } = player || {};
  return (
    <Link to={`/player/${slug}`} className={[styles.main, loading && styles.loading].join(' ')}>
      <span className={styles.draftPosition}>{loading ? ' ' : draftposition}</span>
      <div className={styles.imageSection}>
        <RoundPlayerImageWithBorder src={imageuri} className={styles.playerImage} loading={loading} />
      </div>
      <div className={styles.textSection}>
        <span className={styles.name}>{!loading && name}</span>
        <span className={styles.team}>{!loading && teamfullname}</span>
      </div>
    </Link>
  );
};

export default CompactDraftPickCard;
