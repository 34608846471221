import React, { CSSProperties } from 'react';
import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom';
import articleImage from '../../assets/images/article_icon.svg';
import { formatDate } from '../../common';
import { IContentArticleItem } from '../../store/creatorContent/types';
import styles from './ArticleCard.module.css';

interface IArticleCard {
  loading?: boolean;
  article?: IContentArticleItem;
  className?: string;
  style?: CSSProperties;
  hideDivider?: boolean;
}

export const MobileArticleCard = ({
  className,
  loading,
  style,
  article,
  hideDivider,
}: IArticleCard) => {
  const {
    content,
    id,
    publishedat,
  } = article || {};
  const {
    image,
    title,
  } = content || {};
  return (
    <div style={style}>
      <Link
        to={`/article/${id}`}
        className={[styles.mainMobile, loading && styles.mobileLoading, className].join(' ')}
        style={hideDivider ? { borderBottom: 'none' } : {}}
      >
        <div
          aria-label="Article thumbnail"
          className={styles.cardImgMobile}
          style={{ backgroundImage: loading ? 'none' : `url(${image})` }}
        />
        <div className={styles.cardHeaders}>
          <h4>{!loading && title}</h4>
          <h5>{!loading && publishedat && formatDate(publishedat)}</h5>
        </div>
      </Link>
    </div>
  );
};

const DesktopArticleCard = ({
  className,
  loading,
  style,
  article,
}: IArticleCard) => (
  article ? <div style={style}>
    <Link to={`/article/${article.id}`} className={[styles.main, loading && styles.loading, className].join(' ')}>
      <div
        aria-label="Article thumbnail"
        className={styles.cardImg}
        style={{ backgroundImage: loading ? 'none' : `url(${article.content.image})` }}
      >
        <div className={styles.imgOverlay}>
          <img className={styles.articleIcon} src={articleImage} alt="article icon" />
        </div>
      </div>
      <div className={styles.cardHeaders}>
        <h4>{!loading && article.content.title}</h4>
        <h5>{!loading && article.publishedat && formatDate(article.publishedat)}</h5>
      </div>
    </Link>
  </div> : null
);

const ArticleCard = (props: IArticleCard) => (
  <>
    <MediaQuery minWidth={415}>
      <DesktopArticleCard {...props} />
    </MediaQuery>
    <MediaQuery maxWidth={414}>
      <MobileArticleCard {...props} />
    </MediaQuery>
  </>
);

export default ArticleCard;
