import 'core-js/features/array/find';
import 'core-js/features/array/find-index';
import 'core-js/features/array/includes';
import 'core-js/features/array/keys';
import 'core-js/features/number/is-nan';
import 'core-js/features/string/starts-with';

import 'react-app-polyfill/ie11';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import SentryErrorBoundary from './components/SentryErrorBoundary';
import './index.css';
import * as serviceWorker from './serviceWorker';

ReactDOM.render((
    <SentryErrorBoundary>
      <App />
    </SentryErrorBoundary>
  ),
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
