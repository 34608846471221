import * as firebase from 'firebase/app';
import get from 'lodash/get';

export const CACHE_DURATION = 30 * 60 * 1000; // Cache data for half an hour

export const shouldSkipFetch = (state: any, limit?: number, offset?: number) => {
// existing state.data
  const data = get(state, 'data', []);
  // number of rows returned by server
  const rowCount = get(state, 'rowCount', 0);
  const cacheExpired = Date.now() - state.fetchedAt >= CACHE_DURATION;
  // Get additional data if all data requested or offset specified
  const getMoreData = (offset && offset > 0) || (limit === undefined && offset === undefined);

  if (state.isFetching) { return true; }
  // If data was previously fetched and cache isn't expired
  if (!cacheExpired && state.fetchedAt) {
    // If more data available
    if (getMoreData && data.length < rowCount) {
      // continue with fetch
      return false;
    }
    // skip fetch since no more data is available
    return true;
  }
  // continue with fetch because cache expired or hasn't been called once
  return false;
};

export async function fetchByUrl<ReturnDataType>(
  url: string,
  options?: RequestInit,
  rejectIfError?: boolean,
): Promise<{
  data?: ReturnDataType;
  rowCount?: number;
}> {
let response;

try {
    response = await fetch(
      url,
      {
        ...options,
        headers: {
          'Content-Type': 'application/json',
          ...(options && options.headers),
        },
      });
    if (rejectIfError && response.status >= 400) {
      throw new Error(`${url} ${response.status}`);
    }
    const {
      data,
      rowCount,
    } = await response.json();
    return {
      data,
      rowCount: Number.parseInt(rowCount, 10),
    };
  } catch (error) {
    if (rejectIfError) {
      throw error;
    }
    // tslint:disable-next-line:no-console
    console.log(`fetch error: ${error}`);
    return {};
  }
}

export async function fetchByUrlWithAuth<ReturnDataType>(
  url: string,
  options?: RequestInit,
) {
  const { currentUser } = firebase.auth();
  if (!currentUser) {
    throw new Error('User not logged in, or waiting for auth to load');
  }
  const token = await currentUser.getIdToken();
  const headers = {
    Authorization: `Bearer ${token}`,
    ...(options && options.headers),
  };
  return fetchByUrl<ReturnDataType>(url, { ...options, headers });
}

interface IExpressError {
  value?: string;
  msg?: string;
  param?: string;
}

export const formatExpressErrors = async (response: Response): Promise<string[]> => {
  const { errors } = await response.json();
  if (Array.isArray(errors)) {
    return errors
            .map((e: IExpressError) => e.msg && e.param ? `${e.msg} - ${e.param}` : 'Error');
  }
  return ([response.statusText]);
};
