import queryString from 'query-string';
import { PLAYER_SORT_OPTIONS } from '../components/PageOptions';
import { fetchByUrl } from './common';
import { NextOnesAction } from './index';
import { IIntervalStatRatings } from './leagueStats/types';
import { IPlayerData } from './players/types';
import { IPlayerStats } from './playersStats/types';
import {
  INextOnesDraftPick,
  IPlayerList,
  IPlayerScoutData,
  PlayerDataWithStats,
} from './types';
import {
  API_DRAFT_PICKS,
  API_PLAYER_SCOUTING_CONTENT_URL,
  API_PLAYER_STAT_TOTALS_URL,
  API_PLAYERS_BY_VIDEO_ID_URL,
  API_VIDEOS_URL,
} from './urls';
import { IVideoData } from './videosById/types';

export const getPlayerScoutingContent = (
  playerId: string,
  token: string): NextOnesAction<IPlayerScoutData | undefined>  => async () => {
  const url = `${API_PLAYER_SCOUTING_CONTENT_URL}/${playerId}?token=${token}`;
  const { data } = await fetchByUrl(url);
  return data;
};

const getFilterObj = (field: string, value: any) => {
  const obj = {};
  obj[`__LIKE_SUBSTR__${field}`] = value;
  return (obj);
};

export interface IPreppedData {
  label: string;
  total: number | undefined;
  totalrating: number | undefined;
  average: number | undefined;
  averagerating: number | undefined;
  leagueaveragerating?: number | undefined;
}

const playerTotalStats = [
  { label: 'Points', prefix: 'pts' },
  { label: 'Assists', prefix: 'ast' },
  { label: 'FG %', prefix: 'fg_percent' },
  { label: 'FGM', prefix: 'fgm' },
  { label: 'FT %', prefix: 'ft_percent' },
  { label: 'FTM', prefix: 'ftm' },
  { label: '3-Pointers', prefix: 'three_pm' },
  { label: 'Rebounds', prefix: 'reb' },
  { label: 'Steals', prefix: 'st' },
  { label: 'Blocks', prefix: 'blks' },
];
// Transform player stat total row into array of objects with stat
//  label, total, totalrating, average, and averagerating.  If a stat doesn't
//  have a corresponding average or total, the value will be undefined.
export const prepPlayerTotals = (data?: IPlayerStats): IPreppedData[] => {
  if (!data) { return []; }
  return (
    playerTotalStats.map((stat) => ({
      average: data[`${stat.prefix}_average`],
      averagerating: data[`${stat.prefix}_average_rating`],
      label: stat.label,
      total: data[`${stat.prefix}_total`],
      totalrating: data[`${stat.prefix}_total_rating`],
    }))
  );
};

export const prepPlayerAndIntervalTotals = (
  data?: IPlayerStats,
  intervalData?: IIntervalStatRatings,
): IPreppedData[] => {
  if (!data) { return []; }
  return (
    playerTotalStats
      .filter((s) => s.prefix !== 'fg_percent' && s.prefix !== 'ft_percent')
      .map((stat) => ({
        average: data[`${stat.prefix}_average`],
        averagerating: data[`${stat.prefix}_average_rating`],
        label: stat.label,
        leagueaveragerating: intervalData && intervalData[`${stat.prefix}_average_rating`],
        total: data[`${stat.prefix}_total`],
        totalrating: data[`${stat.prefix}_total_rating`],
      }))
  );
};

export const PLAYER_LIST_TITLES = {
  lastweek: {
    long: 'Weekly Leaderboard',
    short: 'Last Week',
  },
  latest_season: {
    long: 'Season Leaderboard',
    short: 'Season',
  },
};

export interface IGetPlayerListOptions {
  school_year?: string[];
  position?: string[];
  statistic?: string[];
  organization_id?: string[];
}

// Gets a list of player stat totals with all data necessary to populate a list of players
//  on the players page (player name, height weight, and stats to display in charts)
export const getPlayerList = (
  intervalCode: string,
  limit?: number,
  options: IGetPlayerListOptions = {},
): NextOnesAction<IPlayerList> => async () => {
  const {
    school_year,
    position,
    statistic = [],
    organization_id,
  } = options;

  const sort = statistic.map((stat) => {
    const opt = PLAYER_SORT_OPTIONS.find((o) => o.value === stat);
    if (opt) {
      return [opt.field, 'DESC'];
    }
    return [];
  }).filter((o) => o.length);

  if (sort.length === 0) { sort.push(['performance', 'DESC']); }

  const {
    data = [],
    rowCount,
  } = await fetchByUrl<PlayerDataWithStats[]>(`${API_PLAYER_STAT_TOTALS_URL}?${queryString.stringify({
    filter: JSON.stringify({
      organization_id,
      ...getFilterObj('interval_code', intervalCode),
      ...getFilterObj('position', position),
      ...getFilterObj('school_year', school_year),
    }),
    limit,
    sort: JSON.stringify(sort),
  })}`);

  const preppedData = data.map((itm)  => ({
    chartData: prepPlayerTotals(itm),
    fullData: itm,
  }));

  const sortedBy = PLAYER_SORT_OPTIONS.find((opt) => opt.field === sort[0][0]) || {
    field: 'performance',
    value: 'Performance Rating',
  };
  return {
    end_date: data[0] && data[0].end_date,
    intervalCode,
    items: preppedData,
    sortField: sortedBy.field,
    sortTitle: sortedBy.value,
    start_date: data[0] && data[0].start_date,
    title: PLAYER_LIST_TITLES[intervalCode],
    total: rowCount,
  };
};

export const getPlayerLists = (
  intervalCodes: string[],
  limits: number[],
  options?: IGetPlayerListOptions,
): NextOnesAction<IPlayerList[]> => async (dispatch) => {
  return Promise.all(intervalCodes.map((code, index) => dispatch(getPlayerList(code, limits[index], options))));
};

// Get players related to the specified video id
export const getPlayersByVideoId = (
  id: string,
): NextOnesAction<IPlayerData[]> => async () => {
  const { data = [] } = await fetchByUrl(`${API_PLAYERS_BY_VIDEO_ID_URL}?id=${id}`);
  return data;
};

// Get Draft picks data
export const getDraftPicks = (
  limit?: number,
  offset?: number,
): NextOnesAction<INextOnesDraftPick[]> => async () => {
  const { data = [] } = await fetchByUrl(
    `${API_DRAFT_PICKS}?${queryString.stringify({limit, offset})}`,
  );

  return data;
};

export const getVideosByFeatured = (): NextOnesAction<IVideoData[]> => async () => {
  const qLimit = '&limit=10';
  const url = `${API_VIDEOS_URL}?featured=true${qLimit}`;
  const { data = [] } = await fetchByUrl(url);
  return data;
};
