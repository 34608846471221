import range from 'lodash/range';
import React from 'react';
import { INextOnesDraftPick } from '../../store/types';
import ButtonLink from '../Form/ButtonLink';
import CompactDraftPickCard from './CompactDraftPickCard';

import styles from './CompactDraftList.module.css';

interface IDraftPickList {
  title: string;
  loading: boolean;
  players: INextOnesDraftPick[];
  numPlaceholdersToShowWhenLoading?: number;

  smallerFont?: boolean;
}

const CompactDraftList = ({
  title,
  loading,
  players,
  smallerFont = false,
  numPlaceholdersToShowWhenLoading = 10,
}: IDraftPickList) => {
  const firstPlayers = React.useMemo(() => players.slice(0, 10), [players]);
  const draftYear = React.useMemo(
    () => firstPlayers && firstPlayers[0] ? firstPlayers[0].draftyear : window.sessionStorage.getItem('commonConfig__draftYear'),
    [firstPlayers],
  );
  const draftListWithLoadingPlaceholders = React.useMemo(() => {
    const r = [
      ...firstPlayers,
      ...range(loading ? numPlaceholdersToShowWhenLoading : 0).map(() => 'loading'),
    ];
    return r;
  }, [firstPlayers, loading, numPlaceholdersToShowWhenLoading]);
  return (
    <div className={styles.main}>
      <h3 style={smallerFont ? { fontSize: '30px' } : {}}>{`${draftYear} ${title}`}</h3>
      {
        draftListWithLoadingPlaceholders.map((player, i) => (
          typeof player === 'string' ? <CompactDraftPickCard key={`loadingPick${i}`} loading />
          : <CompactDraftPickCard key={`pick${player.draftposition}`} player={player} loading={loading} />))
      }
      <ButtonLink className={styles.buttonLink} to={'/draft'}>View Draft Sleepers</ButtonLink>
    </div>
  );
};

export default CompactDraftList;
