import React from 'react';
import { IContentArticleItem, IContentVideoItem } from '../../store/creatorContent/types';
import { BasicPlayerData } from '../../store/players/types';
import { INextOnesDraftPick } from '../../store/types';
import CompactPlayerList from '../CompactPlayerList/CompactPlayerList';
import CompactDraftList from '../DraftPick/CompactDraftList';
import { HeroSection } from '../HeroSection';
import CompactVideoList from './CompactVideoList';
import styles from './Discover.module.css';
import LatestShowList, { ILatestShow } from './LatestShowList';

interface IDiscover {
  latestShows: ILatestShow[];
  draftPlayers: INextOnesDraftPick[];
  contentPicks: Array<IContentArticleItem | IContentVideoItem>;
  latestShowsLoading: boolean;
  draftLoading: boolean;
  contentPicksLoading: boolean;
  onLoadMore?: () => void;
  showLoadMore?: boolean;
  trendingPlayers: BasicPlayerData[];
  trendingPlayersLoading: boolean;
}

const Discover = ({
  latestShows,
  latestShowsLoading,
  draftPlayers,
  draftLoading,
  contentPicks,
  contentPicksLoading,
  onLoadMore,
  showLoadMore,
  trendingPlayers,
  trendingPlayersLoading,
}: IDiscover) => {
  return (
    <div className={styles.main}>
      {
        <>
          <HeroSection
            data-testid="hero-section"
            bgVideoUrl="https://storage.googleapis.com/samico-nextones.appspot.com/homepage-1000k.mp4"
            className={styles.hero}
          >
            <div className={styles.heroTitle}>
              NEXT ONES
              </div>
            <div className={styles.heroText}>
              A player discovery network built by players for players and their fans
              </div>
          </HeroSection>
          <h2>DISCOVER</h2>
          <div className={styles.contentRow}>
            <div className={styles.contentLeft}>
              <LatestShowList
                loading={latestShowsLoading}
                title="Latest Discoveries"
                latestShows={latestShows}
                onLoadMore={onLoadMore}
                showLoadMore={showLoadMore}
              />
            </div>
            <div className={styles.verticalDivider} />
            <div className={styles.contentRight}>
              <CompactDraftList
                title="Draft Sleepers"
                players={draftPlayers}
                loading={draftLoading}
              />
              <div className={styles.horizontalDivider} />
              <CompactPlayerList
                title="Trending Players"
                players={trendingPlayers}
                loading={trendingPlayersLoading}
              />
              <div className={styles.horizontalDivider} />
              <CompactVideoList
                title="Next Ones Picks"
                loading={contentPicksLoading}
                content={contentPicks}
              />
            </div>
          </div>
        </>
      }
    </div>
  );
};

export default Discover;
