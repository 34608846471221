import {
  ACTION_PLAYERS_STATS_COMPLETE,
  ACTION_PLAYERS_STATS_FAILED,
  ACTION_PLAYERS_STATS_PROCESSING,
  ACTION_PLAYERS_STATS_SINGLE_COMPLETE,
  ACTION_PLAYERS_STATS_SINGLE_FAILED,
  ACTION_PLAYERS_STATS_SINGLE_PROCESSING,
  IAppStatePlayerStats,
  IPlayerStats,
  PlayersStatsAction,
} from './types';

function combinePlayerStats(playerData: Record<string, IPlayerStats>, newData: IPlayerStats | IPlayerStats[]) {
  if (Array.isArray(newData)) {
    const reduced = newData.reduce((accum, stat) => {
      accum[stat.interval_id] = stat;

      return accum;
    }, {});

    return {
      ...playerData,
      ...reduced,
    };
  }

  return {
    ...playerData,
    [newData.interval_id]: newData,
  };
}

const players = (
  state: IAppStatePlayerStats = { data: {} },
  action: PlayersStatsAction,
): IAppStatePlayerStats => {
  switch (action.type) {
    case ACTION_PLAYERS_STATS_PROCESSING:
      return {
        ...state,
        isFetching: new Date(),
      };
    case ACTION_PLAYERS_STATS_COMPLETE:
      if (!action.slug || !action.data) {
        return {
          ...state,
          fetchedAt: new Date(),
          isFetching: false,
        };
      }

      return {
        data: {
          ...state.data,
          [action.slug]: combinePlayerStats(state.data[action.slug], action.data),
        },
        fetchedAt: new Date(),
        isFetching: false,
      };
    case ACTION_PLAYERS_STATS_FAILED:
      return {
        ...state,
        fetchedAt: new Date(),
        isFetching: false,
      };

    case ACTION_PLAYERS_STATS_SINGLE_PROCESSING:
      return {
        ...state,
        fetchedAt: new Date(),
        isFetching: false,
      };
    case ACTION_PLAYERS_STATS_SINGLE_COMPLETE:
      const playerStats = state.data[action.slug];

      // We have no data, just insert it
      if (!playerStats) {
        return {
          data: {
            ...state.data,
            [action.slug]: {
              [action.data.interval_id]: action.data,
            },
          },
          fetchedAt: new Date(),
          isFetching: false,
        };
      }

      const seasons = Object.keys(playerStats);

      // We already have this data so we don't need to add it
      if (seasons.includes(action.data.interval_code)) {
        return {
          ...state,
          fetchedAt: new Date(),
          isFetching: false,
        };
      }

      return {
        data: {
          ...state.data,
          [action.slug]: combinePlayerStats(playerStats, action.data),
        },
        fetchedAt: new Date(),
        isFetching: false,
      };
    case ACTION_PLAYERS_STATS_SINGLE_FAILED:
      return {
        ...state,
        fetchedAt: new Date(),
        isFetching: false,
       };

    default:
      return state;
  }
};

export default players;
