import {
  fetchByUrl,
  shouldSkipFetch,
} from '../common';
import {
  NextOnesAction,
} from '../index';
import {
  API_FEATURED_PLAYLISTS_URL,
} from '../urls';
import {
  ACTION_PLAYLISTS_COMPLETE,
  ACTION_PLAYLISTS_FAILED,
  ACTION_PLAYLISTS_PROCESSING,
  IPlaylistData,
} from './types';

export const getPlaylists = (
): NextOnesAction<IPlaylistData[] | null> => async (dispatch, getState) => {
  const { playlists } = getState();

  if (shouldSkipFetch(playlists)) { return playlists.data; }

  dispatch({ type: ACTION_PLAYLISTS_PROCESSING });
  const { data } = await fetchByUrl<IPlaylistData[]>(API_FEATURED_PLAYLISTS_URL);

  if (data) {
    dispatch({ type: ACTION_PLAYLISTS_COMPLETE, data });
    return (data);
  }

  dispatch({ type: ACTION_PLAYLISTS_FAILED, data });
  return null;
};

export const getPlaylist = (
  id: string,
): NextOnesAction<IPlaylistData | undefined> => async (dispatch) => {
  const playlists = (await dispatch(getPlaylists()) || []);
  return playlists.find((playlist) => playlist.id === id);
};
