import { NextOnesAction } from '..';
import {
  fetchByUrl,
} from '../common';
import { ISearchOptions, toGetListQueryString } from '../searchResultsByKeyword/actions';
import { API_PLAYERS } from '../urls';
import {
  ACTION_PLAYERS_COMPLETE,
  ACTION_PLAYERS_FAILED,
  ACTION_PLAYERS_PROCESSING,
  ACTION_PLAYERS_SINGLE_COMPLETE,
  ACTION_PLAYERS_SINGLE_FAILED,
  ACTION_PLAYERS_SINGLE_PROCESSING,
  BasicPlayerData,
  IPlayerData,
} from './types';

export interface IGetPlayersOptions {
  force?: boolean;
  limit?: number;
  offset?: number;
  sort?: string;
  filter?: any;
}
export const getPlayers = ({
  force,
  limit,
  offset,
  sort,
  filter,
  token,
}: ISearchOptions = {}): NextOnesAction<{rowCount?: number, data?: BasicPlayerData[] }> =>
async (dispatch) => {
  const url = API_PLAYERS;

  dispatch({ type: ACTION_PLAYERS_PROCESSING });

  const { data, rowCount } = await fetchByUrl<BasicPlayerData[]>(`${url}?${toGetListQueryString({limit, offset, sort, filter, token})}`);

  if (data) {
    dispatch({ type: ACTION_PLAYERS_COMPLETE, data, rowCount });
    return {
      data,
      rowCount,
    };
  }

  dispatch({ type: ACTION_PLAYERS_FAILED });
  return {};
};

export const getPlayer = (playerId: string): NextOnesAction<IPlayerData | undefined> => async (dispatch) => {
  dispatch({ type: ACTION_PLAYERS_SINGLE_PROCESSING });

  const { data } = await fetchByUrl<IPlayerData>(`${API_PLAYERS}/${playerId}`);

  if ( data ) {
    dispatch({ type: ACTION_PLAYERS_SINGLE_COMPLETE, data });
    return data;
  }

  dispatch({ type: ACTION_PLAYERS_SINGLE_FAILED });
  return;
};
