import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { getToken } from '../../common';
import StandardHeader, { IHeaderProps, ITab } from './StandardHeader';

// Routes that will appear in the header
const HEADER_ROUTES: ITab[] = [
  {
    id: 0,
    label: 'DISCOVER',
    to: '/',
  },
  {
    id: 1,
    label: 'LEADERBOARDS',
    to: '/analyze',
  },
  {
    id: 2,
    label: 'WATCH',
    to: '/shows',
  },
  {
    id: 3,
    label: 'DATABASE',
    to: '/explorer',
  },
];

const SCOUTING_ROUTE: ITab = {
  id: 4,
  label: 'SCOUTING',
  to: '/explorer-for-scouts',
};

const LEGAL_TABS: ITab[] = [
  {
    id: 98,
    label: 'Terms And Conditions',
    to: '/terms-of-use',
  },
  {
    id: 99,
    label: 'Privacy Policy',
    to: '/privacy-policy',
  },
];

const OTHER_ROUTES: ITab[] = [
  {
    id: -99,
    label: '',
    to: '/',
  },
];

const getRouteIdByLocation = (location = {} as RouteComponentProps['location']) => {
  const tab = [
    ...HEADER_ROUTES,
    SCOUTING_ROUTE,
    ...OTHER_ROUTES,
  ].find((t) => {
    const pathname = location.pathname || '';
    if (pathname.startsWith('/explorer/') && t.to === '/explorer') {
      return true;
    }
    if (pathname.startsWith('/profile/') && t.to === '/profile') {
      return true;
    }
    return t.to === pathname;
  });
  return (tab ? tab.id : -1);
};

function Header({ activeTab, searchenabled = true, location, user, ...props }: IHeaderProps) {
  if (activeTab === undefined) {
    activeTab = getRouteIdByLocation(location);
  }
  const [showInput, setShowInput] = React.useState(false);
  const [showDrawer, setShowDrawer] = React.useState(false);

  const toggleInput = React.useCallback(() => setShowInput((prevValue) => !prevValue), []);
  const toggleDrawer = React.useCallback(() => setShowDrawer((prevValue) => !prevValue), []);

  let tabs: ITab[] = [...HEADER_ROUTES];

  // Only show scouting route when there is a valid cookie
  if (getToken()) {
    tabs = [
      ...tabs,
      SCOUTING_ROUTE,
    ];
  }

  const ref = React.useRef<HTMLDivElement>(null);

  /**
   * Add click listener when drawer is open to close the drawer when
   * clicking outside the drawer.
   */
  React.useEffect(() => {
    if (showDrawer) {
      const handleWindowClick = (e: MouseEvent) => {
        if (ref.current && !ref.current.contains(e.target as HTMLElement)) {
          setShowDrawer(false);
        }
      };
      window.addEventListener('click', handleWindowClick);
      return () => window.removeEventListener('click', handleWindowClick);
    }
    return () => undefined;
  }, [showDrawer]);

  return (
    <StandardHeader
      {...props}
      ref={ref}
      tabs={tabs}
      legalTabs={LEGAL_TABS}
      activeTab={activeTab}
      location={location}
      toggleInput={toggleInput}
      showInput={showInput}
      searchenabled={searchenabled}
      showDrawer={showDrawer}
      toggleDrawer={toggleDrawer}
      user={user}
    />
  );
}

export default Header;
