import { IPlayerData } from '../players/types';
import {
  ICommon,
} from '../types';

export const ACTION_USER_LOADING = 'ACTION_USER_LOADING';
export const ACTION_USER_SET = 'ACTION_USER_SET';
export const ACTION_HYVOR_GUEST_SET = 'ACTION_HYVOR_GUEST_SET';

interface IHyvorUser {
  hash: string;
  userData: string;
}

export interface IUserMeApiResponse {
  player: (Partial<IPlayerData> & Pick<IPlayerData, 'slug' | 'name'>) | null;
  hyvorUser?: IHyvorUser;
}

export interface IUser extends firebase.User {
  player?: IUserMeApiResponse['player'];
  slug?: string;
  profile_image?: string;
  hyvorUser?: IHyvorUser;
  user_role?: 'fan' | 'player' | 'creator' | 'scout';
}

export interface IAppStateUser extends ICommon {
  data: IUser | null;
}

interface IUserLoadingAction {
  type: typeof ACTION_USER_LOADING;
  data?: null;
}

interface IUserSetAction {
  type: typeof ACTION_USER_SET;
  data: firebase.User | null;
}

export type UserAction =
  IUserLoadingAction |
  IUserSetAction;
