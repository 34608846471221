import { IPlayerData } from '../players/types';
import { IsFetching } from '../types';

export interface IVideoSearchResults {
  channellogo: string | null;
  hashtags: string;
  publishedat: string;
  title: string;
  videoid: string;
}

export type KeywordSearchPlayer = Pick<IPlayerData, 'slug' | 'imageuri' | 'name' | 'team_full_name'>;
export interface ISearchResultsData {
  players: KeywordSearchPlayer[];
  videos: IVideoSearchResults[];
}
export interface IAppStateSearchResults {
  [keyword: string]: {
    data?: ISearchResultsData,
    fetchedAt?: Date,
    isFetching?: IsFetching,
  };
}

export const ACTION_SEARCH_RESULTS_BY_KEYWORD_PROCESSING = 'ACTION_SEARCH_RESULTS_BY_KEYWORD_PROCESSING';
export const ACTION_SEARCH_RESULTS_BY_KEYWORD_COMPLETE = 'ACTION_SEARCH_RESULTS_BY_KEYWORD_COMPLETE';
export const ACTION_SEARCH_RESULTS_BY_KEYWORD_FAILED = 'ACTION_SEARCH_RESULTS_BY_KEYWORD_FAILED';

interface ISearchResultsActionProcessing {
  type: typeof ACTION_SEARCH_RESULTS_BY_KEYWORD_PROCESSING;
  keyword: string;
  data?: ISearchResultsData;
}
interface ISearchResultsActionComplete {
  type: typeof ACTION_SEARCH_RESULTS_BY_KEYWORD_COMPLETE;
  keyword: string;
  data?: ISearchResultsData;
}
interface ISearchResultsActionFailed {
  type: typeof ACTION_SEARCH_RESULTS_BY_KEYWORD_FAILED;
  keyword: string;
  data?: ISearchResultsData;
}

export type SearchResultsAction =
  ISearchResultsActionProcessing |
  ISearchResultsActionComplete |
  ISearchResultsActionFailed;

export enum FilterFields {
  Name = 'name',
  Position = 'position',
  School = 'school',
  Description = 'description',
  Title = 'title',
}
