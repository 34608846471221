import React from 'react';
import { toTimeAgo } from '../../common';
import { IContentUserPostItem } from '../../store/creatorContent/types';
import { DisplayName } from '../PlayerDetail/TimelineCards/CreatorHeader';
import styles from './LatestUserPostCard.module.css';

interface ILatestUserPostCard {
  post?: IContentUserPostItem;
  loading?: boolean;
}

const LatestUserPostCard = ({
  post,
  loading,
}: ILatestUserPostCard) => {
  const {
    content,
    creator,
    publishedat = '',
  } = post || {};
  const {
    body = '',
  } = content || {};
  const formattedTime = React.useMemo(() => (publishedat ? toTimeAgo(publishedat) : ''), [publishedat]);
  return (
    <div className={styles.main}>
      <div className={styles.creatorRow}>
        <DisplayName creator={creator} />
        <span className={styles.creatorLabel}>shared a status update</span>
      </div>
      {publishedat && <span className={styles.timeAgo}>{formattedTime}</span>}
      <span className={styles.body}>{body}</span>
    </div>
  );
};

export default LatestUserPostCard;
