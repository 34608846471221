import {
  ICommon,
} from '../types';

export interface IPlaylistData {
  description: string | null;
  highlightsorder: number | null;
  id: string;
  insightsorder: number | null;
  publishedat: string;
  title: string;
  shortdescription?: string | null;
  longdescription?: string | null;
  cardimage?: string | null;
  bannerimage?: string | null;
}
export interface IAppStatePlaylists extends ICommon {
  data: IPlaylistData[];
}

export const ACTION_PLAYLISTS_PROCESSING = 'ACTION_PLAYLISTS_PROCESSING';
export const ACTION_PLAYLISTS_COMPLETE = 'ACTION_PLAYLISTS_COMPLETE';
export const ACTION_PLAYLISTS_FAILED = 'ACTION_PLAYLISTS_FAILED';

interface IPlaylistsActionProcessing {
  type: typeof ACTION_PLAYLISTS_PROCESSING;
  data: IPlaylistData[];
}
interface IPlaylistsActionComplete {
  type: typeof ACTION_PLAYLISTS_COMPLETE;
  data: IPlaylistData[];
}
interface IPlaylistsActionFailed {
  type: typeof ACTION_PLAYLISTS_FAILED;
  data: IPlaylistData[];
}

export type PlaylistsAction =
  IPlaylistsActionProcessing |
  IPlaylistsActionComplete |
  IPlaylistsActionFailed;
