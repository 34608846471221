import React from 'react';
import { connect } from 'react-redux';
import { useScrollToTop } from '../../hooks';
import { AppState } from '../../store';
import {
  getDraftPicks as getDraftPicksAction,
} from '../../store/actions';
import {
  getCreatorContent as getCreatorContentAction,
  getCreatorContentByFeatured as getCreatorContentByFeaturedAction,
  getFollowedCreatorContent as getFollowedCreatorContentAction,
  IGetCreatorContent,
} from '../../store/creatorContent/actions';
import {
  IContentArticleItem,
  IContentUserPostItem,
  IContentVideoItem,
  instanceOfIContentVideoItem,
} from '../../store/creatorContent/types';
import {
  getPlayers as getPlayersAction,
} from '../../store/players/actions';
import { BasicPlayerData } from '../../store/players/types';
import {
  getPlaylists as getPlaylistsAction,
} from '../../store/playlists/actions';
import { IPlaylistData } from '../../store/playlists/types';
import { ISearchOptions } from '../../store/searchResultsByKeyword/actions';
import { INextOnesDraftPick } from '../../store/types';
import { IUser } from '../../store/user/types';
import { IVideoDataWithCreator } from '../../store/videos/types';
import Discover from './Discover';
import { ILatestShow } from './LatestShowList';

const VIDEOS_TO_LOAD = 10;

interface IDiscoverController {
  getCreatorContent: (opts?: IGetCreatorContent) => Promise<{
    rowCount?: number,
    data?: Array<IContentArticleItem | IContentVideoItem | IContentUserPostItem>,
  }>;
  getCreatorContentByFeatured: () => Promise<Array<IContentArticleItem | IContentVideoItem>>;
  getDraftPicks: (limit?: number, offset?: number) => Promise<INextOnesDraftPick[]>;
  getFollowedCreatorContent: (opts?: ISearchOptions) => Promise<{
    rowCount?: number,
    data?: Array<IContentArticleItem | IContentVideoItem | IContentUserPostItem>,
  }>;
  getPlaylists: () => Promise<IPlaylistData[] | null>;
  getVideos: (opts?: ISearchOptions) => Promise<{rowCount?: number, data?: IVideoDataWithCreator[]}>;
  getPlayers: (opts?: ISearchOptions) => Promise<{rowCount?: number, data?: BasicPlayerData[]}>;
  user?: IUser | null;
  userLoading?: boolean | Date;
}

const DiscoverController = ({
  getCreatorContent,
  getCreatorContentByFeatured,
  getDraftPicks,
  getFollowedCreatorContent,
  getPlayers,
  getPlaylists,
  user,
  userLoading,
}: IDiscoverController) => {
  const [latestShows, setLatestShows] = React.useState<ILatestShow[]>([]);
  const [latestShowsLoading, setLatestShowsLoading] = React.useState(true);
  const [draftPicks, setDraftPicks] = React.useState<INextOnesDraftPick[]>([]);
  const [draftLoading, setDraftLoading] = React.useState(true);
  const [contentPicks, setContentPicks] = React.useState<Array<IContentArticleItem | IContentVideoItem>>([]);
  const [contentPicksLoading, setContentPicksLoading] = React.useState(true);
  const [showLoadMore, setShowLoadMore] = React.useState(false);
  const [showOffset, setShowOffset] = React.useState(0);
  const [showCount, setShowCount] = React.useState(0);
  const [playlists, setPlaylists] = React.useState([] as IPlaylistData[]);
  const [trendingPlayers, setTrendingPlayers] = React.useState<BasicPlayerData[]>([]);
  const [trendingPlayersLoading, setTrendingPlayersLoading] = React.useState(true);

  useScrollToTop();

  const getContent = React.useMemo(
    () => {
      if (userLoading) {
        return undefined;
      }
      return user ? getFollowedCreatorContent : getCreatorContent;
    },
    [user, userLoading, getFollowedCreatorContent, getCreatorContent],
  );

  // Fetch playlists on load
  React.useEffect(() => {
    setLatestShowsLoading(true);
    getPlaylists()
      .then((p) => {
        if (p) {
          setPlaylists(p);
        }
      });
  }, [getPlaylists]);

  // Fetch draft picks on load
  React.useEffect(() => {
    getDraftPicks().then((picks) => {
      setDraftPicks(picks);
      setDraftLoading(false);
    });
  }, [getDraftPicks]);

  // Fetch next ones content picks
  React.useEffect(() => {
    getCreatorContentByFeatured().then((picks) => {
      setContentPicks(picks);
      setContentPicksLoading(false);
    });
  }, [getCreatorContentByFeatured]);

  // Fetch trending players
  React.useEffect(() => {
    getPlayers({
      filter: {
        trending_rank: 'not null',
      },
      limit: 10,
      sort: [['trending_rank', 'ASC']],
    }).then(({ data }) => {
      if (data) {
        setTrendingPlayers(data);
      }
      setTrendingPlayersLoading(false);
    });
  }, [getPlayers]);

  // Fetch videos after loading playlists
  React.useEffect(
    () => {
      if (playlists.length > 0 && getContent) {
        getContent({
          content_type: ['article', 'video'], // Only used by getCreatorContent
          limit: VIDEOS_TO_LOAD,
          offset: showOffset,
          sort: [['publishedat', 'DESC']],
        })
        .then(({ data: content, rowCount = 0 }) => {
          if (content) {
            const lShows = content
              .filter((c) => (!c.creator || c.creator.type !== 'admin'))
              .map((c) => (
              (c.creator && c.creator.type === 'show' && instanceOfIContentVideoItem(c)) ? {
                contentItem: c,
                show: playlists.find((p) => p.id === (c && c.content.playlistid)),
              } : { contentItem: c }));
            setShowCount(rowCount);
            setLatestShows((l) => [...l, ...lShows] as ILatestShow[]);
            setLatestShowsLoading(false);
          }
        });
      }
    },
    [playlists, showOffset, getContent, setShowCount],
  );

  // Show load more if number of available videos <
  // number currently loaded
  React.useEffect(
    () => {
      setShowLoadMore(!latestShowsLoading && latestShows.length < showCount);
    },
    [latestShows.length, showCount, setShowLoadMore, latestShowsLoading],
  );

  const onLoadMore = React.useCallback(() => setShowOffset((offset) => offset + VIDEOS_TO_LOAD), [setShowOffset]);
  return (
    <Discover
      draftLoading={draftLoading}
      draftPlayers={draftPicks}
      contentPicks={contentPicks}
      contentPicksLoading={contentPicksLoading}
      latestShows={latestShows}
      latestShowsLoading={latestShowsLoading}
      showLoadMore={showLoadMore}
      onLoadMore={onLoadMore}
      trendingPlayers={trendingPlayers}
      trendingPlayersLoading={trendingPlayersLoading}
    />
  );
};

const mapStateToProps = ({ user }: AppState) => ({
  user: user.data,
  userLoading: user.isFetching,
});

const mapDispatchToProps = {
  getCreatorContent: getCreatorContentAction,
  getCreatorContentByFeatured: getCreatorContentByFeaturedAction,
  getDraftPicks: getDraftPicksAction,
  getFollowedCreatorContent: getFollowedCreatorContentAction,
  getPlayers: getPlayersAction,
  getPlaylists: getPlaylistsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscoverController);
