import {
  ACTION_PLAYERS_SPOTLIGHTS_COMPLETE,
  ACTION_PLAYERS_SPOTLIGHTS_FAILED,
  ACTION_PLAYERS_SPOTLIGHTS_PROCESSING,
  IAppStatePlayersSpotlights,
  PlayersSpotlightsAction,
} from './types';

const playlists = (
  state: IAppStatePlayersSpotlights = { data: [] },
  action: PlayersSpotlightsAction,
): IAppStatePlayersSpotlights => {
  const {
    type,
    data,
  } = action;
  switch (type) {
    case ACTION_PLAYERS_SPOTLIGHTS_PROCESSING:
      return {
        ...state,
        isFetching: new Date(),
      };
    case ACTION_PLAYERS_SPOTLIGHTS_COMPLETE:
      return {
        ...state,
        data,
        fetchedAt: new Date(),
        isFetching: false,
      };
    case ACTION_PLAYERS_SPOTLIGHTS_FAILED:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default playlists;
