import { NextOnesAction } from '..';
import { fetchByUrl, fetchByUrlWithAuth } from '../common';
import { ISearchOptions, toGetListQueryString } from '../searchResultsByKeyword/actions';
import { API_CREATOR_CONTENT, API_FOLLOWED_CREATOR_CONTENT_URL } from '../urls';
import { IContentArticleItem, IContentUserPostItem, IContentVideoItem } from './types';

export interface IGetCreatorContent extends ISearchOptions {
  content_type?: string[];
  creatorId?: string;
  id?: string;
}

export const getCreatorContent = ({
  content_type,
  creatorId,
  id,
  limit,
  offset,
  sort,
  filter,
  token,
}: IGetCreatorContent = {}): NextOnesAction<{
  rowCount?: number,
  data?: Array<IContentArticleItem | IContentVideoItem | IContentUserPostItem>,
}> =>
async () => {
  let url;
  if (id) {
    url = `${API_CREATOR_CONTENT}?id=${id}${toGetListQueryString({limit, offset, sort, filter, token})}`;
  } else if (content_type) {
    const cType = encodeURIComponent(content_type.join(','));
    url = `${API_CREATOR_CONTENT}?content_type=${cType}&${toGetListQueryString({limit, offset, sort, filter, token})}`;
  } else if (creatorId) {
    url = `${API_CREATOR_CONTENT}?creatorId=${creatorId}&${toGetListQueryString({limit, offset, sort, filter, token})}`;
  } else {
    url = `${API_CREATOR_CONTENT}?${toGetListQueryString({limit, offset, sort, filter, token})}`;
  }

  const {
    data,
    rowCount,
  } = await fetchByUrl<Array<IContentArticleItem | IContentVideoItem | IContentUserPostItem>>(url);

  if (data) {
    return {
      data,
      rowCount,
    };
  }

  return {};
};

export const getFollowedCreatorContent = ({
  limit,
  offset,
  sort,
  filter,
  token,
}: ISearchOptions = {}): NextOnesAction<{
  rowCount?: number,
  data?: Array<IContentArticleItem | IContentVideoItem | IContentUserPostItem>,
}> =>
async () => {
  const url = API_FOLLOWED_CREATOR_CONTENT_URL;

  const {
    data,
    rowCount,
  } = await fetchByUrlWithAuth<Array<IContentArticleItem | IContentVideoItem | IContentUserPostItem>>(`${url}?${toGetListQueryString({limit, offset, sort, filter, token})}`);

  if (data) {
    return {
      data,
      rowCount,
    };
  }

  return {};
};

export const getCreatorContentByFeatured = (): NextOnesAction<Array<IContentArticleItem | IContentVideoItem>> =>
async () => {
  const qLimit = '&limit=10';
  const url = `${API_CREATOR_CONTENT}?featured=true${qLimit}`;
  const { data = [] } = await fetchByUrl(url);
  return data;
};
