import { IsFetching } from '../types';
import { IVideoData } from '../videosById/types';

export interface IAppStateVideosByPlaylistId {
  [id: string]: {
    data?: IVideoData[],
    fetchedAt?: Date,
    isFetching?: IsFetching,
    rowCount?: string,
  };
}

export const ACTION_VIDEOS_BY_PLAYLISTID_PROCESSING = 'ACTION_VIDEOS_BY_PLAYLISTID_PROCESSING';
export const ACTION_VIDEOS_BY_PLAYLISTID_COMPLETE = 'ACTION_VIDEOS_BY_PLAYLISTID_COMPLETE';
export const ACTION_VIDEOS_BY_PLAYLISTID_FAILED = 'ACTION_VIDEOS_BY_PLAYLISTID_FAILED';

interface IVideosByPlaylistIdProcessing {
  type: typeof ACTION_VIDEOS_BY_PLAYLISTID_PROCESSING;
  id: string;
  rowCount?: string;
  data?: IVideoData[];
}
interface IVideosByPlaylistIdComplete {
  type: typeof ACTION_VIDEOS_BY_PLAYLISTID_COMPLETE;
  id: string;
  rowCount?: string;
  data?: IVideoData[];
}
interface IVideosByPlaylistIdFailed {
  type: typeof ACTION_VIDEOS_BY_PLAYLISTID_FAILED;
  id: string;
  rowCount?: string;
  data?: IVideoData[];
}

export type VideosByPlayListIdAction =
  IVideosByPlaylistIdProcessing |
  IVideosByPlaylistIdComplete |
  IVideosByPlaylistIdFailed;
