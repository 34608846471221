import React, { CSSProperties } from 'react';
import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom';
import playButton from '../../assets/images/play_button.png';
import {
  formatDate,
  ICardListItem,
} from '../../common';
import styles from './VideoCard.module.css';

interface IVideoCard extends ICardListItem {
  loading?: boolean;
  style?: CSSProperties;
  className?: string;
  hideDivider?: boolean;
}

export const MobileVideoCard = ({
  className,
  imageURI,
  loading,
  publishedat,
  style,
  title,
  to,
  hideDivider,
}: IVideoCard) => (
  <div style={style}>
    <Link
      to={to}
      className={[styles.mainMobile, loading && styles.mobileLoading, className].join(' ')}
      style={hideDivider ? { borderBottom: 'none' } : {}}
    >
      <div
        aria-label="Video thumbnail"
        className={styles.cardImgMobile}
        style={{ backgroundImage: loading ? 'none' : `url(${imageURI})` }}
      />
      <div className={styles.cardHeaders}>
        <h4>{!loading && title}</h4>
        <h5>{!loading  && formatDate(publishedat)}</h5>
      </div>
    </Link>
  </div>
);

const DesktopVideoCard = ({
  className,
  imageURI,
  loading,
  publishedat,
  style,
  title,
  to,
}: IVideoCard) => (
  <div style={style}>
    <Link to={to} className={[styles.main, loading && styles.loading, className].join(' ')}>
      <div
        aria-label="Video thumbnail"
        className={styles.cardImg}
        style={{ backgroundImage: loading ? 'none' : `url(${imageURI})` }}
      >
        <div className={styles.imgOverlay}>
          <img className={styles.playButton} alt="logo" src={playButton} />
        </div>
      </div>
      <div className={styles.cardHeaders}>
        <h4>{!loading && title}</h4>
        <h5>{!loading  && formatDate(publishedat)}</h5>
      </div>
    </Link>
  </div>
);

const VideoCard = (props: IVideoCard) => (
  <>
    <MediaQuery minWidth={415}>
      <DesktopVideoCard {...props} />
    </MediaQuery>
    <MediaQuery maxWidth={414}>
      <MobileVideoCard {...props} />
    </MediaQuery>
  </>
);

// Width and height of SimplePlayerCard + 2 for right and bottom padding
export const VideoCardListWidth =  360 + 2; // Design shows 445, but tablet looks better with thinner columns
export const VideoCardListHeight = 305 + 2;

export default VideoCard;
