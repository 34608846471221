import { NextOnesAction } from '..';
import { fetchByUrlWithAuth } from '../common';
import { ICreator } from '../creator/types';
import {
  API_USER_FOLLOWS_BY_SLUG_URL,
  API_USER_FOLLOWS_URL,
} from '../urls';
import { IUserFollows } from './types';

export const addUserFollows = (slug: string, followType: string): NextOnesAction<any> => async () => {
  return fetchByUrlWithAuth(`${API_USER_FOLLOWS_URL}`, { method: 'POST', body: JSON.stringify({ slug, followType }) });
};

export const deleteUserFollows = (slug: string, followType: string): NextOnesAction<any> => async () => {
  return fetchByUrlWithAuth(
    `${API_USER_FOLLOWS_URL}`,
    { method: 'DELETE', body: JSON.stringify({ slug, followType }) },
  );
};

export const getUserFollowsBySlug = (
    slug: string, followType: string,
  ): NextOnesAction<IUserFollows> => async () => {
  const { data } = await fetchByUrlWithAuth(
    `${API_USER_FOLLOWS_BY_SLUG_URL}?slug=${slug}&followType=${followType}`,
    { method: 'GET' },
  );
  return data;
};

export const getUserFollows = (): NextOnesAction<ICreator[]> => async () => {
const { data } = await fetchByUrlWithAuth(
  `${API_USER_FOLLOWS_URL}`,
  { method: 'GET' },
);
return data;
};
