import { faPlay, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

import { IPlaylistData } from '../../store/playlists/types';

import styles from './ShowCard.module.css';

interface IPlayOverlayProps extends React.HTMLAttributes<HTMLDivElement> {
  show?: boolean;
  overlayed?: boolean;
  onQuestionPress?: () => void;
  showId?: string;
}

const PlayOverlay = ({ show, overlayed, onQuestionPress, showId, ...props }: IPlayOverlayProps) => (
  <div
    className={[
      styles.playOverlay,
      show && styles.playOverlayVisible,
      overlayed && styles.playOverlayOverlayed,
    ].filter((c) => c).join(' ')}
    {...props}
  >
    {onQuestionPress && (
      <button data-testid="question-button" className={styles.cornerButton} onClick={onQuestionPress}>
          ?
      </button>
    )}

    <Link data-testid="play-button" className={styles.playButton} to={`/show/${showId}`}>
      <FontAwesomeIcon icon={faPlay} />
    </Link>
  </div>
);

interface IDescriptionProps extends React.HTMLAttributes<HTMLDivElement> {
  show?: boolean;
  onClose: () => void;
}

const Description = ({ show, onClose, children, ...props }: IDescriptionProps) => (
  <div
    className={[styles.description, show && styles.descriptionVisible].filter((c) => c).join(' ')}
    {...props}
  >
    <button data-testid="close-description-button" className={styles.cornerButton} onClick={onClose}>
      <FontAwesomeIcon icon={faTimes} />
    </button>
    <div>
      {children}
    </div>
  </div>
);

interface IShowCardProps extends React.HTMLAttributes<HTMLDivElement> {
  showInfo?: IPlaylistData;
}

const ShowCard = ({
  showInfo,
  style,
  className,
 }: IShowCardProps) => {
  const [playVisible, setPlayVisible] = React.useState(false);
  const [descVisible, setDescVisible] = React.useState(false);
  const showPlay = (e: React.MouseEvent) => {
    if ('ontouchstart' in window && e.type === 'mouseenter') {
      // Skip if it's a touch device and 'mouseenter' is fired because
      // 'click' is also fixed and this causes the browser to click the
      // <Link> in the <PlayOverlay>
      return;
    }
    setPlayVisible(true);
  };
  const hidePlay = () => setPlayVisible(false);
  const showDescription = () => setDescVisible(true);
  const hideDescription = () => setDescVisible(false);

  return (
    <div style={style}>
      <div
        className={[styles.main, className].join(' ')}
        onMouseEnter={showPlay}
        onMouseLeave={hidePlay}
        onClick={showPlay}
      >
        <div className={styles.content}>
          <div className={styles.imageOrTitle}>
            {showInfo && showInfo.cardimage ? (
              <img
                className={styles.image}
                src={showInfo.cardimage}
                alt={showInfo.title}
              />
            ) : (
              <div className={styles.title}>{showInfo && showInfo.title}</div>
            )}
          </div>
          <PlayOverlay
            show={descVisible || playVisible}
            overlayed={descVisible}
            onQuestionPress={showInfo && showInfo.description ? showDescription : undefined}
            showId={showInfo && showInfo.id}
          />
          <Description show={descVisible} onClose={hideDescription}>
            {showInfo && (showInfo.shortdescription || showInfo.description || showInfo.longdescription)}
          </Description>
        </div>
      </div>
    </div>
  );
};

export default ShowCard;
