import get from 'lodash/get';
import queryString from 'query-string';

import { PLAYER_POSITION_FILTERS } from '../../components/PageOptions';

import { IGetPlayerListOptions } from '../actions';
import {
  fetchByUrl,
  shouldSkipFetch,
} from '../common';
import {
  NextOnesAction,
} from '../index';
import { IPlayerData } from '../players/types';
import {
  API_KEYWORD_SEARCH_URL,
  API_SEARCH_FOR_PLAYERS_URL,
} from '../urls';
import {
  ACTION_SEARCH_RESULTS_BY_KEYWORD_COMPLETE,
  ACTION_SEARCH_RESULTS_BY_KEYWORD_FAILED,
  ACTION_SEARCH_RESULTS_BY_KEYWORD_PROCESSING,
  FilterFields,
  ISearchResultsData,
  KeywordSearchPlayer,
} from './types';

export const getSearchResultsByKeyword = (
  keyword: string,
  { only, fields }: { only?: string[], fields?: FilterFields[] } = {},
  ): NextOnesAction<ISearchResultsData> => async (dispatch, getState) => {
  const { searchResultsByKeyword } = getState();
  let url = `${API_KEYWORD_SEARCH_URL}?keyword=${keyword}`;
  if (only && only.length) {
    // Add only[]=VALUE1&only[]=VALUE2
    url = `${url}&${queryString.stringify({ only }, { arrayFormat: 'bracket' })}`;
  }
  if (fields && fields.length) {
    url = `${url}&${queryString.stringify({ fields }, { arrayFormat: 'bracket' })}`;
  }

  const stateForKey = get(searchResultsByKeyword, `[${keyword}]`, {});
  const prevData = get(stateForKey, 'data', []);
  if (shouldSkipFetch(stateForKey)) { return prevData; }

  dispatch({ type: ACTION_SEARCH_RESULTS_BY_KEYWORD_PROCESSING, keyword });

  const { data } = await fetchByUrl(url);

  if (data) {
    dispatch({ type: ACTION_SEARCH_RESULTS_BY_KEYWORD_COMPLETE, keyword, data });
    return (data);
  }

  dispatch({ type: ACTION_SEARCH_RESULTS_BY_KEYWORD_FAILED, data });
  return null;
};

export const getPlayersByKeywordOrOptions = (
  keyword: string | null,
  options: IGetPlayerListOptions,
): NextOnesAction<KeywordSearchPlayer[]> => async (dispatch, getState) => {
  if (keyword) {
    const results = await dispatch(getSearchResultsByKeyword(keyword, { only: ['players'] })) as ISearchResultsData;
    return (results && results.players) || [];
  }
  if (options) {
    const results = await dispatch(getSearchResultsByKeyword(
      (!!options.position && options.position[0]) || PLAYER_POSITION_FILTERS.options[0].value,
      { only: ['players'], fields: [FilterFields.Position] },
    )) as ISearchResultsData;
    return (results && results.players) || [];
  }
  return [];
};

export type SortDirection = 'ASC' | 'DESC';
export type Sort = [string, SortDirection];

export interface IGetListOptions {
  filter?: {
    [field: string]: string | undefined;
  };
  // sort is an array of [field, direction] arrays
  sort?: Sort[];
  offset?: number;
  limit?: number;
  force?: boolean;
}

export type GetListActionReturnType<T> = NextOnesAction<{
  rowCount?: number;
  data?: T[];
}>;

export interface ISearchOptions extends IGetListOptions {
  filter?: {
    [field: string]: string | undefined;
    q?: string;
  };
  token?: string | null;
}

export function toGetListQueryString(qs: any) {
  const qsCopy = { ...qs };
  if (qs.filter) {
    qsCopy.filter = JSON.stringify(qs.filter);
  }
  if (qs.sort) {
    qsCopy.sort = JSON.stringify(qs.sort);
  }
  return queryString.stringify(qsCopy, { arrayFormat: 'bracket' });
}

export const searchForPlayers = (
  options: ISearchOptions = {},
): GetListActionReturnType<IPlayerData> => async () => {
  const qs = toGetListQueryString(options);
  const url = `${API_SEARCH_FOR_PLAYERS_URL}?${qs}`;
  return fetchByUrl<IPlayerData[]>(url, undefined, true);
};
