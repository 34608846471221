import { IsFetching } from '../types';
import { IVideoData } from '../videosById/types';

type VideoByPlayerFields = 'playlistid' | 'hashtags' | 'publishedat' | 'title' | 'videoid';
export interface IVideoDataByPlayerId extends Pick<IVideoData, VideoByPlayerFields> {}

export interface IAppStateVideosByPlayerId {
  [id: string]: {
    data?: IVideoDataByPlayerId[],
    fetchedAt?: Date,
    rowCount?: number,
    isFetching?: IsFetching,
  };
}

export const ACTION_VIDEOS_BY_PLAYER_ID_PROCESSING = 'ACTION_VIDEOS_BY_PLAYER_ID_PROCESSING';
export const ACTION_VIDEOS_BY_PLAYER_ID_CLEAR = 'ACTION_VIDEOS_BY_PLAYER_ID_CLEAR';
export const ACTION_VIDEOS_BY_PLAYER_ID_COMPLETE = 'ACTION_VIDEOS_BY_PLAYER_ID_COMPLETE';
export const ACTION_VIDEOS_BY_PLAYER_ID_FAILED = 'ACTION_VIDEOS_BY_PLAYER_ID_FAILED';

interface IVideosByPlayerIdActionProcessing {
  type: typeof ACTION_VIDEOS_BY_PLAYER_ID_PROCESSING;
  rowCount: number;
  id: string;
  data: IVideoDataByPlayerId[];
}
interface IVideosByPlayerIdActionClear {
  type: typeof ACTION_VIDEOS_BY_PLAYER_ID_CLEAR;
  rowCount: number;
  id: string;
  data: IVideoDataByPlayerId[];
}
interface IVideosByPlayerIdActionComplete {
  type: typeof ACTION_VIDEOS_BY_PLAYER_ID_COMPLETE;
  rowCount: number;
  id: string;
  data: IVideoDataByPlayerId[];
}
interface IVideosByPlayerIdActionFailed {
  type: typeof ACTION_VIDEOS_BY_PLAYER_ID_FAILED;
  rowCount: number;
  id: string;
  data: IVideoDataByPlayerId[];
}
export type VideosByPlayerIdAction =
  IVideosByPlayerIdActionProcessing |
  IVideosByPlayerIdActionComplete |
  IVideosByPlayerIdActionFailed |
  IVideosByPlayerIdActionClear;
