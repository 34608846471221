import {
  ACTION_USER_LOADING,
  ACTION_USER_SET,
  IAppStateUser,
  UserAction,
} from './types';

export default (
  state: IAppStateUser = {
    data: null,
    isFetching: true,
  },
  action: UserAction,
): IAppStateUser => {
  const {
    type,
    data,
  } = action;
  switch (type) {
    case ACTION_USER_LOADING:
      return {
        ...state,
        isFetching: true,
      };
    case ACTION_USER_SET:
      return {
        data: data || null,
        isFetching: false,
      };
    default:
      return state;
  }
};
