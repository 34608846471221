import cookie from 'cookie';
import jwtDecode from 'jwt-decode';
import queryString from 'query-string';
import { IContentVideoItem } from '../store/creatorContent/types';
import { IPlayerData } from '../store/players/types';
import { IVideoData } from '../store/videosById/types';

// Accepts an array of video objects and uses the data to return
//  an array of objects to displayed in a card list
export interface ICardListItem {
  title: string;
  time: string;
  to: string;
  imageURI: string;
  logoImgURI: string | null;
  hashtags: string[];
  publishedat: string;
  videoid: string;
}

export function instanceOfICardListItem(object: any): object is ICardListItem {
  return 'imageURI' in object && 'videoid' in object && 'publishedat' in object && 'logoImgURI' in object;
}

export function toTimeAgo(publishedat: string) {
  const milHour = 1000 * 60 * 60;
  const milDay = milHour * 24;
  let time = '';

  const dateDiff = Date.now() - new Date(publishedat).getTime();

  if (dateDiff <= milHour) {
    time = '1 hour ago';
  } else if (dateDiff <= milDay) {
    time = `${Math.round(dateDiff / milHour)} hours ago`;
  } else if (dateDiff < milDay * 2) {
    time = '1 day ago';
  } else if (dateDiff <= milDay * 30) {
    time = `${Math.round(dateDiff / milDay)} days ago`;
  } else if (dateDiff < milDay * 60) {
    time = '1 month ago';
  } else if (dateDiff <= milDay * 365) {
    time = `${Math.round(dateDiff / (milDay * 30))} months ago`;
  } else {
    time = 'Over a year ago';
  }
  return time;
}

export const cardListItemFromVideo = (videoData: IVideoData, playerId?: string) => {
  const {
    title,
    publishedat,
    videoid,
    hashtags,
    channellogo,
    type,
  } = videoData;

  let imageURI = `https://i.ytimg.com/vi/${videoid}/mqdefault.jpg`;
  if (type === 'jwplayer') {
    imageURI = `https://cdn.jwplayer.com/thumbs/${videoid}.jpg`;
  }

  return ({
    hashtags: hashtags ? hashtags.split(',') : [],
    imageURI,
    logoImgURI: channellogo,
    publishedat,
    time: toTimeAgo(publishedat),
    title,
    to: `/video/${videoid}${playerId ? `?p=${playerId}` : ''}`,
    videoid,
  });
};

export const cardListItemFromContentVideo = (videoData: IContentVideoItem, playerId?: string) => {
  const {
    publishedat,
    content,
  } = videoData;
  const {
    title,
    videoid,
    hashtags,
    channellogo,
    type,
  } = content;

  let imageURI = `https://i.ytimg.com/vi/${videoid}/mqdefault.jpg`;
  if (type === 'jwplayer') {
    imageURI = `https://cdn.jwplayer.com/thumbs/${videoid}.jpg`;
  }

  return ({
    hashtags: hashtags ? hashtags.split(',') : [],
    imageURI,
    logoImgURI: channellogo,
    publishedat,
    time: toTimeAgo(publishedat),
    title,
    to: `/video/${videoid}${playerId ? `?p=${playerId}` : ''}`,
    videoid,
  });
};

export const cardListItemsFromVideos = (videoData: IVideoData[], playerId?: string) => {
  const items: ICardListItem[] = [];

  if (videoData) {
    videoData.forEach((vid: IVideoData) => {
      items.push(cardListItemFromVideo(vid, playerId));
    });
  }
  return items;
};

export const formatDate = (date: string) => {
  const newDate = new Date(date);
  return (
    `${newDate.getMonth() + 1} . ${newDate.getDate()} . ${newDate.getFullYear().toString().substring(2)}`
  );
};

interface IGetTokenOptions {
  saveToCookieIfFoundInQueryString?: boolean;
}

/**
 * Get a auth token by checking for a token query param
 * and fallback to cookie
 */
export function getToken({ saveToCookieIfFoundInQueryString = true }: IGetTokenOptions = {}): string | null {
  let token: string | null = null;

  const query = queryString.parse(window.location.search);
  if (query && query.token) {
    token = Array.isArray(query.token) ? query.token[0] : query.token;
    if (saveToCookieIfFoundInQueryString) {
      const serializedCookie = cookie.serialize('token', token);
      document.cookie = serializedCookie;
    }
  }

  if (!token) {
    const cookies = cookie.parse(document.cookie);
    token = cookies.token;
  }
  // Only return if it's a parsable JWT token and it isn't expired.
  // This doesn't check if signature is valid (the backend will
  // take care of that).
  try {
    const decodedToken = jwtDecode(token) as any;
    if (decodedToken && decodedToken.exp) {
      const now = new Date();
      if (now.getTime() < decodedToken.exp * 1000) {
        return token;
      }
    }
  } catch (err) {
    // Ignore
  }
  // Remove 'token' cookie since it's invalid
  document.cookie = 'token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
  return null;
}

export function formatValue(value?: number, label?: string, fractionalDigits: number = 0): string {
  let valueStr: string = '- -';
  if (value !== undefined) {
    if (label && label.indexOf('%') >= 0) {
      valueStr = (value! * 100).toFixed(fractionalDigits);
    } else {
      valueStr = value.toFixed(fractionalDigits);
    }
  }
  return valueStr;
}

export function formatFollowerCount(value: number, label = 'Fan'): string {
  if (value < 1000) {
    return `${value} ${label}${value !== 1 ? 's' : ''}`;
  } else if (value < 1000000) {
    return `${(value * .001).toFixed(1)}K ${label}s`;
  }
  return `${(value * .000001).toFixed(1)}M ${label}s`;
}

export const formatHeight = (val?: number | string | null) => val && val !== `' "` ? val  : '- -';
export const formatWeight = (val?: number | string | null) => val ? `${val} lbs`  : '- -';
export const formatInteger = (val?: number | string | null) => {
  if (val === '- -' || val === undefined || val === null) {
    return '- - ';
  }
  const n = typeof val === 'number' ? val : Number.parseInt(val, 10);
  return n.toLocaleString('en-US', { useGrouping: true });
};

// RoundPlayerImage Style is different if using team or league logos
// A logo is used if `player` is defined and doesn't have an imageuri but has either logos
type playerLogoTypes = 'imageuri' | 'team_logo' | 'league_logo';
export const isPlayerLogo = (player?: Pick<IPlayerData, playerLogoTypes>) => (
  !!(player && (!player.imageuri && (player.team_logo || player.league_logo)))
);
