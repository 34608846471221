import range from 'lodash/range';
import React from 'react';
import { BasicPlayerData } from '../../store/players/types';
import CompactPlayerCard from './CompactPlayerCard';
import styles from './CompactPlayerList.module.css';

interface IPlayerList {
  title: string;
  loading: boolean;
  players: BasicPlayerData[];
  numPlaceholdersToShowWhenLoading?: number;
}

const CompactPlayerList = ({
  title,
  loading,
  players,
  numPlaceholdersToShowWhenLoading = 10,
}: IPlayerList) => {
  const firstPlayers = React.useMemo(() => players.slice(0, 10), [players]);
  const trendingListWithLoadingPlaceholders = React.useMemo(() => {
    const r = [
      ...firstPlayers,
      ...range(loading ? numPlaceholdersToShowWhenLoading : 0).map(() => 'loading'),
    ];
    return r;
  }, [firstPlayers, loading, numPlaceholdersToShowWhenLoading]);
  return (
    <div className={styles.main}>
      <h3>{title}</h3>
      {
        trendingListWithLoadingPlaceholders.map((player, i) => (
          typeof player === 'string' ? <CompactPlayerCard key={`loadingTrending${i}`} loading />
          : (
          <CompactPlayerCard
            key={`trending${player.trending_rank}`}
            player={{ ...player, trending_rank: i + 1 }}
            loading={loading}
          />
        )))
      }
    </div>
  );
};

export default CompactPlayerList;
