import React from 'react';
import OriginalDocumentTitle from 'react-document-title';

interface IDocumentTitleWithFallbackProps {
  title?: string | null;
  fallbackTitle?: string;
  children: React.ReactNode;
}

const DocumentTitle = ({
  title,
  fallbackTitle = 'Next Ones - basketball player discovery network',
  ...props
}: IDocumentTitleWithFallbackProps) => (
  <OriginalDocumentTitle
    title={title ? `${title} - ${fallbackTitle}` : fallbackTitle}
    {...props}
  />
);

export default DocumentTitle;
