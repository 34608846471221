import React from 'react';

import styles from './Button.module.css';

interface IButtonProps extends React.HTMLProps<HTMLButtonElement> {
  invert?: boolean;
  small?: boolean;
}

const Button = ({
  className = '',
  type,
  invert,
  small,
  ...rest
}: IButtonProps) => (
  <button
    className={`${styles.main} ${invert ? styles.invert : '' } ${small ? styles.small : '' }  ${className}`}
    type={type as 'submit' | 'reset' | 'button' | undefined}
    {...rest}
  />
);

export default Button;
