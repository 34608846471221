import get from 'lodash/get';
import { IContentArticleItem, IContentVideoItem } from '../creatorContent/types';
import {
    ACTION_CONTENT_BY_PLAYER_ID_CLEAR,
    ACTION_CONTENT_BY_PLAYER_ID_COMPLETE,
    ACTION_CONTENT_BY_PLAYER_ID_FAILED,
    ACTION_CONTENT_BY_PLAYER_ID_PROCESSING,
    ContentByPlayerIdAction,
    IAppStateContentByPlayerId,
} from './types';

const contentByPlayerId = (
  state: IAppStateContentByPlayerId = {},
  action: ContentByPlayerIdAction,
): IAppStateContentByPlayerId => {
  const {
    type,
    id,
    data = [],
    rowCount,
  } = action;
  const stateData = get(state, `[${id}].data`, []);
  switch (type) {
    case ACTION_CONTENT_BY_PLAYER_ID_PROCESSING:
      return {
        ...state,
        [id]: {
          ...state[id],
          isFetching: new Date(),
        },
      };
    case ACTION_CONTENT_BY_PLAYER_ID_COMPLETE:
      return {
        ...state,
        [id]: {
          data: [
            ...stateData as Array<IContentArticleItem | IContentVideoItem>,
            ...data,
          ],
          fetchedAt: new Date(),
          isFetching: false,
          rowCount,
        },
      };
    case ACTION_CONTENT_BY_PLAYER_ID_CLEAR:
      return {
        ...state,
        [id]: {
          data: [],
          fetchedAt: new Date(),
          isFetching: false,
          rowCount,
        },
      };
    case ACTION_CONTENT_BY_PLAYER_ID_FAILED:
      return {
        ...state,
        [id]: {
          isFetching: false,
        },
      };
    default:
      return state;
  }
};

export default contentByPlayerId;
