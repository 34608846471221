import { ICommon } from '../types';

export interface IPlayerSeason {
  interval_id: number;
  code: string;
  league: string;
}

export interface IPlayerData {
  id: string;
  firstname: string | null;
  lastname: string | null;
  fullname_clean: string | null;
  description: string | null;
  school_year: string | null;
  playernumber: string | null;
  heightfeet: string | null;
  heightinches: string | null;
  weight: string | null;
  youtube: string | null;
  twitter: string | null;
  instagram: string | null;
  university: string | null;
  smallimage: string | null;
  shareimage: string | null;
  bareimage: string | null;
  hometown: string | null;
  slug: string;
  recruit_status: string | null;
  height: string | null;
  name: string;
  raw_performance: number | null;
  playerrating: number | null;
  lastweekrank: number | null;
  nextonesrank: number | null;
  latestperformance: number | null;
  position: string | null;
  imageuri: string | null;
  seasons: IPlayerSeason[] | null;
  team_full_name?: string | null;
  trending_rank?: number | null;

  league_id?: string | null;
  league_name?: string | null;
  league_logo?: string | null;

  team_id?: string | null;
  team_name?: string | null;
  team_logo?: string | null;
  nextones_follower_count?: number;
}

type BasicPlayerDataTypes =
  'id' |
  'slug' |
  'firstname' |
  'lastname' |
  'imageuri' |
  'recruit_status' |
  'playerrating' |
  'league_id' |
  'league_name' |
  'league_logo' |
  'team_id' |
  'team_name' |
  'team_logo' |
  'trending_rank';

export type BasicPlayerData = Pick<IPlayerData, BasicPlayerDataTypes>;

export interface IAppStatePlayers extends ICommon {
  /**
   * Due to how player data might be loaded it can either be
   *   just the basic data or complete player data
   */
  data: Array<BasicPlayerData | IPlayerData>;
  rowCount?: number;
}

export const ACTION_PLAYERS_PROCESSING = 'ACTION_PLAYERS_PROCESSING';
export const ACTION_PLAYERS_COMPLETE = 'ACTION_PLAYERS_COMPLETE';
export const ACTION_PLAYERS_FAILED = 'ACTION_PLAYERS_FAILED';

export const ACTION_PLAYERS_SINGLE_PROCESSING = 'ACTION_PLAYERS_SINGLE_PROCESSING';
export const ACTION_PLAYERS_SINGLE_COMPLETE = 'ACTION_PLAYERS_SINGLE_COMPLETE';
export const ACTION_PLAYERS_SINGLE_FAILED = 'ACTION_PLAYERS_SINGLE_FAILED';

interface IPlayersActionProcessing {
  type: typeof ACTION_PLAYERS_PROCESSING;
}
interface IPlayersActionComplete {
  type: typeof ACTION_PLAYERS_COMPLETE;
  rowCount: number;
  data: BasicPlayerData[];
}
interface IPlayersActionFailed {
  type: typeof ACTION_PLAYERS_FAILED;
}

interface IPlayersActionSingleProcessing {
  type: typeof ACTION_PLAYERS_SINGLE_PROCESSING;
}
interface IPlayersActionSingleComplete {
  type: typeof ACTION_PLAYERS_SINGLE_COMPLETE;
  rowCount: number;
  data: IPlayerData;
}
interface IPlayersActionSingleFailed {
  type: typeof ACTION_PLAYERS_SINGLE_FAILED;
}

export type PlayersAction =
  IPlayersActionProcessing |
  IPlayersActionComplete |
  IPlayersActionFailed |
  IPlayersActionSingleProcessing |
  IPlayersActionSingleComplete |
  IPlayersActionSingleFailed;
