import React from 'react';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../common';
import { ICreator } from '../../../store/creator/types';
import { RoundPlayerImageWithBorder } from '../../PlayerImage/PlayerImage';

import styles from './CreatorHeader.module.css';

export const DisplayName = ({ creator }: { creator?: ICreator }) => {
  const {
    type,
    displayname = '',
    profile_key,
  } = creator || {};
  switch (type) {
    case 'show':
      return <Link className={styles.displayNameLink} to={`/show/${profile_key}`}>{displayname}</Link>;
    case 'player':
      return <Link className={styles.displayNameLink} to={`/player/${profile_key}`}>{displayname}</Link>;
    case 'creator':
      return <Link className={styles.displayNameLink} to={`/creator/${profile_key}`}>{displayname}</Link>;
    case 'admin':
      return <Link className={styles.displayNameLink} to="/">{displayname}</Link>;
    default:
      return <span className={styles.displayName}>{displayname}</span>;
  }
};

interface ICreatorHeader {
  creator?: ICreator;
  creatorLabel?: string;
  publishedat: string;
}

const CreatorHeader = ({
  creator,
  creatorLabel,
  publishedat,
}: ICreatorHeader) => {
  return (
    <div className={styles.main}>
      <RoundPlayerImageWithBorder
        className={styles.creatorImage}
        src={creator ? creator.image : undefined}
      />
      <DisplayName creator={creator} />
      {creatorLabel && <span className={styles.creatorLabel}>{creatorLabel}</span>}
      <span className={styles.publishedAt}>{formatDate(publishedat)}</span>
    </div>
  );
};

export default CreatorHeader;
