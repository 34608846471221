import React from 'react';
import {
  cardListItemFromContentVideo,
  instanceOfICardListItem,
} from '../../common';
import {
  IContentArticleItem,
  IContentVideoItem,
  instanceOfIContentVideoItem,
} from '../../store/creatorContent/types';
import ArticleCard from '../ArticleCard/ArticleCard';
import VideoCard from '../SearchResults/VideoCard';
import styles from './CompactVideoList.module.css';

interface IDraftPickList {
  title: string;
  loading: boolean;
  content: Array<IContentArticleItem | IContentVideoItem>;
}

const CompactVideoList = ({
  title,
  loading,
  content,
}: IDraftPickList) => {
  const cardListItems = React.useMemo(() => content.map((feedItem) => {
    if (instanceOfIContentVideoItem(feedItem)) {
      return (cardListItemFromContentVideo(feedItem));
    } else {
      return feedItem;
    }
  }) , [content]);
  return (
    <div className={styles.main}>
      <h3>{title}</h3>
      {
        cardListItems.map((cardItem, i) => {
            if (instanceOfICardListItem(cardItem)) {
              return (
                <VideoCard
                  className={styles.videoCard}
                  key={`vidPick${cardItem.videoid}${i}`}
                  loading={loading}
                  {...cardItem}
                />
              );
            } else {
              return (
                <ArticleCard key={`articlePick${cardItem.id}`} loading={loading} article={cardItem} />
              );
            }
          })
      }
    </div>
  );
};

export default CompactVideoList;
