import React from 'react';
import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom';
import { toTimeAgo } from '../../common';
import { IContentVideoItem } from '../../store/creatorContent/types';
import { IPlaylistData } from '../../store/playlists/types';
import FollowButton from '../FollowButton';
import InlineVideoPlayer from '../InlineVideoPlayer/InlineVideoPlayer';
import ShowCard from '../ShowCard';
import SimpleCreatorCard from '../SimpleCreatorCard/SimpleCreatorCard';
import styles from './LatestShowCard.module.css';

interface ILatestShowCardProps {
  video?: IContentVideoItem;
  show?: IPlaylistData;
  loading?: boolean;
}

const LatestShowCard = ({
  loading,
  show,
  video,
}: ILatestShowCardProps) => {
  const {
    creator,
    content,
    publishedat,
  } = video || {};
  const {
    title,
    videoid,
  } = content || {};
  const linkTo = React.useMemo(() => (
    (creator && creator.type !== 'admin') ?  `/${creator.type}/${creator.profile_key}` : '/'
  ), [creator]);
  const linkTitle = React.useMemo(() => (
    (creator && creator.type !== 'admin') ?  creator.displayname : 'Next Ones'
  ), [creator]);
  const formattedTime = React.useMemo(() => (publishedat ? toTimeAgo(publishedat) : ''), [publishedat]);
  return (
    <div className={[styles.main, loading && styles.loading].join(' ')}>
      <div className={styles.row}>
        <div className={styles.video}>
          <InlineVideoPlayer loading={loading} video={content} />
        </div>
        {show ? <ShowCard className={styles.showCard} showInfo={show} />
          : <SimpleCreatorCard className={styles.showCard} loading={loading} {...creator!} />}
      </div>
      <div className={styles.mobileNameRow}>
        <Link className={styles.showName} to={linkTo}>{linkTitle}</Link>
        {!show && creator && (
        <MediaQuery maxWidth={849}>
          <div className={styles.followButton}>
            <FollowButton useLinkStyle slug={creator.profile_key} followType={creator.type} />
          </div>
        </MediaQuery>)}
      </div>
      <Link to={`/video/${videoid}`} className={styles.title}>{!loading && title}</Link>
      {(publishedat || loading) && <span className={styles.timeAgo}>{formattedTime}</span>}
    </div>
  );
};

export default LatestShowCard;
