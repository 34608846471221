import React, { CSSProperties } from 'react';
import classes from './index.module.css';
import loadingGifWhite from './loading-white.gif';
import loadingGif from './loading.gif';

interface ILoadingProps {
  message?: string;
  className?: string;
  style?: CSSProperties;
  useWhiteBg?: boolean;
}
const Loading = ({
  message = 'LOADING',
  className,
  style,
  useWhiteBg,
}: ILoadingProps) => (
  <div className={[classes.main, className].join(' ')} style={style}>
    <img className={classes.gif} src={useWhiteBg ? loadingGifWhite : loadingGif} alt="Bouncing Ball" />
    <div className={classes.message}>{message}</div>
  </div>
);

export default Loading;
