import {
  ACTION_SEARCH_RESULTS_BY_KEYWORD_COMPLETE,
  ACTION_SEARCH_RESULTS_BY_KEYWORD_FAILED,
  ACTION_SEARCH_RESULTS_BY_KEYWORD_PROCESSING,
  IAppStateSearchResults,
  SearchResultsAction,
} from './types';

const searchResultsByKeyword = (
  state: IAppStateSearchResults = {},
  action: SearchResultsAction,
): IAppStateSearchResults => {
  const {
    type,
    keyword,
    data,
  } = action;
  switch (type) {
    case ACTION_SEARCH_RESULTS_BY_KEYWORD_PROCESSING:
      return {
        ...state,
        [keyword]: {
          isFetching: new Date(),
        },
      };
    case ACTION_SEARCH_RESULTS_BY_KEYWORD_COMPLETE:
      return {
        ...state,
        [keyword]: {
          data,
          fetchedAt: new Date(),
          isFetching: false,
        },
      };
    case ACTION_SEARCH_RESULTS_BY_KEYWORD_FAILED:
      return {
        ...state,
        [keyword]: {
          isFetching: false,
        },
      };
    default:
      return state;
  }
};

export default searchResultsByKeyword;
