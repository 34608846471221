import React from 'react';
import {
  IOrganizations,
} from '../../store/organizations/types';
import PageOptions from './PageOptions';

// name: name to display in filter component
// field: name of field to sort by on backend
export const PLAYER_SORT_OPTIONS = Object.freeze([
  { value: 'Points', name: 'Points', field: 'pts_average' },
  { value: 'Assists', name: 'Assists', field: 'ast_average' },
  { value: 'FG%', name: 'FG%', field: 'fg_percent_average' },
  { value: 'FT%', name: 'FT%', field: 'ft_percent_average' },
  { value: '3-Pointers', name: '3-Pointers', field: 'three_pm_average' },
  { value: 'Rebounds', name: 'Rebounds', field: 'reb_average' },
  { value: 'Steals', name: 'Steals', field: 'st_average' },
  { value: 'Blocks', name: 'Blocks', field: 'blks_average' },
]);

export const PLAYER_POSITION_FILTERS = {
  id: 'position',
  name: 'Position',
  options: [
    { value: 'Forward', name: 'Forward' },
    { value: 'Guard', name: 'Guard' },
    { value: 'Center', name: 'Center' },
  ],
  type: 'filter',
};

export const PLAYER_CLASS_FILTERS =   {
  id: 'school_year',
  name: 'Class',
  options: [
    { value: 'Freshman', name: 'Freshman' },
    { value: 'Sophomore', name: 'Sophomore' },
    { value: 'Junior', name: 'Junior' },
    { value: 'Senior', name: 'Senior' },
  ],
  type: 'filter',
};

export const PLAYER_PAGE_OPTION_GROUPS = Object.freeze([
  PLAYER_CLASS_FILTERS,
  PLAYER_POSITION_FILTERS,
  {
    id: 'statistic',
    name: 'Rank by Statistic',
    options: PLAYER_SORT_OPTIONS,
    type: 'sort',
  },
]);

export interface IOptionsState {
  position?: string[];
  school_year?: string[];
  statistic?: string[];
  league?: string[];
}

interface IPageOptionsWithStateProps {
  leagues?: IOrganizations[];
  onChange: (state: IOptionsState) => void;
  onOptionClick?: (optionGroupId: string, optionValue: string) => void;
  allowMultipleSelectedPerGroup?: boolean;
  closeOnOptionClick?: boolean;
  optionGroups?: any;
  defaultOptions?: IOptionsState;
}

// Like PageOptions but manages state of selected options and call onChange when state changes
export const PageOptionsWithState = ({
  leagues = [],
  onChange,
  onOptionClick,
  optionGroups,
  allowMultipleSelectedPerGroup = false,
  closeOnOptionClick = false,
  defaultOptions = { league: ['NBA'] },
  ...props
}: IPageOptionsWithStateProps) => {
  const [open, setOpen] = React.useState(false);
  const handleToggleclick = React.useCallback(() => setOpen((v) => !v), [setOpen]);
  const [state, setState] = React.useState<IOptionsState>(defaultOptions);
  const leagueOpts = React.useMemo(() => (
    {
      id: 'league',
      name: 'League',
      options: leagues
        .filter((l) => l.name !== 'ncaawb') // Disable ncaa women's since we only have post-season stats
        .map((l) => ({ value: l.id, name: l.display_name || '' })),
      type: 'filter',
    }
  ), [leagues]);
  const handleOptionClick = React.useCallback((id, value) => {
    if (onOptionClick) {
      onOptionClick(id, value);
    }
    setState((prevState: IOptionsState) => {
      const shouldRemove = prevState[id] && prevState[id].includes(value);

      if (shouldRemove && id !== 'league') {
        // Remove value
        return ({
          ...prevState,
          [id]: allowMultipleSelectedPerGroup ? prevState[id].filter((v: string) => v !== value) : [],
        });
      } else if (shouldRemove && id === 'league') {
        // Don't allow the removal of the league filter
        return (prevState);
      }

      // Clear school year only when league change is from college => pro
      if (id === 'league' && !!prevState.school_year && !value.includes('NCAA')) {
        delete prevState.school_year;
      }

      // Add value
      return ({
        ...prevState,
        [id]: allowMultipleSelectedPerGroup ? [...(prevState[id] || []), value] : [value],
      });
    });
    if (closeOnOptionClick) {
      setOpen(false);
    }
  }, [setState, onOptionClick, closeOnOptionClick, setOpen, allowMultipleSelectedPerGroup]);

  // If state changes call onChange
  React.useEffect(() => {
    // Only call onChange when state has values. If it has no keys then it's
    // the initial state (i.e. just initialized) so no need to call onChange
    // beause nothing has changed yet
    if (onChange && Object.keys(state).length) {
      onChange(state);
    }
  }, [state, onChange]);

  return (
    <PageOptions
      onOptionClick={handleOptionClick}
      optionGroups={[
        leagueOpts,
        ...optionGroups,
      ]}
      selectedOptionsByGroup={state}
      onToggleClick={handleToggleclick}
      open={open}
      {...props}
    />
  );
};

export const PlayersPageOptions = ({
  onChange,
  leagues,
  defaultOptions,
  ...props
}: { onChange: (state: IOptionsState) => void, leagues: IOrganizations[], defaultOptions?: IOptionsState }) => (
  <PageOptionsWithState
    defaultOptions={defaultOptions}
    leagues={leagues}
    optionGroups={PLAYER_PAGE_OPTION_GROUPS}
    onChange={onChange}
    {...props}
  />
);

export default PageOptions;
