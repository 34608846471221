import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties, Ref } from 'react';
import { useHeightToggle } from '../../hooks';

import styles from './PageOptions.module.css';

interface IToggleButtonProps {
  label: string;
  onClick: () => void;
}
const ToggleButton = ({ label, onClick }: IToggleButtonProps) => (
  <>
    {/* tslint:disable-next-line:jsx-no-lambda */}
    <button className={styles.ToggleButton} onClick={() => onClick()}>
      <div className={styles.ToggleButtonIcon}>
        <div />
        <div />
        <div />
      </div>
      <div className={styles.ToggleButtonLabel}>
        {label}
      </div>
    </button>
  </>
);

interface IOptionGroupProps {
  id: string;
  name: string;
  options: Array<{
    name: string;
    field?: string;
    id?: string;
  }>;
  selectedOptions: string[];
  onOptionClick: (optionGroupId: string, optionValue: string) => void;
}

const OptionGroup = ({
  id,
  name,
  options = [],
  selectedOptions = [],
  onOptionClick,
}: IOptionGroupProps) => (
  <div className={styles.OptionGroup}>
    <div className={styles.OptionGroupName}>{name}</div>
    <div className={styles.OptionGroupBody}>
      {options.map((option) => (
        <button
          key={option.id || option.name}
          className={[
            styles.OptionGroupOption,
            selectedOptions.includes(option.name) && styles.OptionGroupOptionSelected,
          ].join(' ')}
          // tslint:disable-next-line:jsx-no-lambda
          onClick={() => (onOptionClick && onOptionClick(id, option.name))}
        >
          <div className={styles.OptionGroupOptionLabel}>
            {option.name}
          </div>
          {
            id !== 'league' && (
              <div className={styles.OptionGroupOptionX}>
                <FontAwesomeIcon icon={faTimes} />
              </div>
            )
          }
        </button>
      ))}
    </div>
  </div>
);

interface IOptionGroups {
  id: string;
  name: string;
  options: Array<{
    name: string;
    field?: string;
  }>;
  type: string;
}

interface IOptionsProps {
  optionGroups: IOptionGroups[];
  selectedOptionsByGroup?: {
    school_year?: string[];
    position?: string[];
    statistic?: string[];
  };
  onOptionClick: (optionGroupId: string, optionValue: string) => void;
}

const Options = ({
  optionGroups,
  selectedOptionsByGroup = {},
  onOptionClick,
}: IOptionsProps) => (
  <div className={styles.Options}>
    {optionGroups.map((optionGroup) => (
      <OptionGroup
        key={optionGroup.id}
        {...optionGroup}
        onOptionClick={onOptionClick}
        selectedOptions={selectedOptionsByGroup[optionGroup.id]}
      />
    ))}
  </div>
);

interface IPageOptionsProps {
  label?: string;
  optionGroups?: IOptionGroups[];
  selectedOptionsByGroup: {
    school_year?: string[];
    position?: string[];
    statistic?: string[];
    league?: string[];
  };
  onOptionClick: (optionGroupId: string, optionValue: string) => void;
  onToggleClick: () => void;
  open: boolean;
}

const PageOptions = ({
  label = 'Filter',
  optionGroups = [],
  selectedOptionsByGroup = {},
  onOptionClick,
  onToggleClick,
  open,
}: IPageOptionsProps) => {
  // Class filter should only be shown for college leagues. So default this to be hidden
  let filteredOptions = optionGroups.filter((opt) => opt.id !== 'school_year');

  const [toggleHeightRef, toggleHeightStyle] = useHeightToggle(open);

  // Show class section filter iff this is a college league
  if (selectedOptionsByGroup.league && selectedOptionsByGroup.league.length > 0) {
    // If chosen league contains NCAA show full option list else show everything but class
    filteredOptions = /^NCAA/.test(selectedOptionsByGroup.league[0]) ? optionGroups : filteredOptions;
  }

  return (
    <div className={styles.main}>
      <ToggleButton label={label} onClick={onToggleClick} />

      <div ref={toggleHeightRef as Ref<HTMLDivElement>} style={toggleHeightStyle as CSSProperties}>
        <div className={styles.body}>
          <Options
            optionGroups={filteredOptions}
            selectedOptionsByGroup={selectedOptionsByGroup}
            onOptionClick={onOptionClick}
          />
        </div>
      </div>
    </div>
  );
};

export default PageOptions;
