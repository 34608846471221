import { IsFetching } from '../types';

export interface IOrganizations {
  id: string;
  name: string | null;
  display_name: string | null;
  type: string | null;
}

export interface IAppStateOrganizationsByType {
  [orgType: string]: {
    data?: IOrganizations[],
    fetchedAt?: Date,
    isFetching?: IsFetching,
  };
}

export const ACTION_ORGANIZATIONS_BY_TYPE_PROCESSING = 'ACTION_ORGANIZATIONS_PROCESSING';
export const ACTION_ORGANIZATIONS_BY_TYPE_COMPLETE = 'ACTION_ORGANIZATIONS_COMPLETE';
export const ACTION_ORGANIZATIONS_BY_TYPE_FAILED = 'ACTION_ORGANIZATIONS_FAILED';

interface IOrganizationsByTypeActionProcessing {
  type: typeof ACTION_ORGANIZATIONS_BY_TYPE_PROCESSING;
  data: IOrganizations[];
  orgType: string;
}
interface IOrganizationsByTypeActionComplete {
  type: typeof ACTION_ORGANIZATIONS_BY_TYPE_COMPLETE;
  rowCount: number;
  data: IOrganizations[];
  orgType: string;
}
interface IOrganizationsByTypeActionFailed {
  type: typeof ACTION_ORGANIZATIONS_BY_TYPE_FAILED;
  data: IOrganizations[];
  orgType: string;
}

export type OrganizationsByTypeAction =
  IOrganizationsByTypeActionProcessing |
  IOrganizationsByTypeActionComplete |
  IOrganizationsByTypeActionFailed;
