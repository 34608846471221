import React from 'react';

import { Link, LinkProps } from 'react-router-dom';

import styles from './Button.module.css';

interface IButtonLinkProps extends LinkProps {
  invert?: boolean;
}

const ButtonLink = ({
  className = '',
  invert,
  ...rest
}: IButtonLinkProps) => (
  <Link
    className={`${styles.main} ${invert ? styles.invert : '' } ${className}`}
    {...rest}
  />
);

export default ButtonLink;
