import {
  ACTION_VIDEOS_BY_ID_COMPLETE,
  ACTION_VIDEOS_BY_ID_FAILED,
  ACTION_VIDEOS_BY_ID_PROCESSING,
  IAppStateVideosById,
  VideosByIdAction,
} from './types';

const videosById = (
  state: IAppStateVideosById = {},
  action: VideosByIdAction,
): IAppStateVideosById => {
  const {
    type,
    id,
    data,
  } = action;
  switch (type) {
    case ACTION_VIDEOS_BY_ID_PROCESSING:
      return {
        ...state,
        [id]: {
          isFetching: new Date(),
        },
      };
    case ACTION_VIDEOS_BY_ID_COMPLETE:
      return {
        ...state,
        [id]: {
          data,
          fetchedAt: new Date(),
          isFetching: false,
        },
      };
    case ACTION_VIDEOS_BY_ID_FAILED:
      return {
        ...state,
        [id]: {
          isFetching: false,
        },
      };
    default:
      return state;
  }
};

export default videosById;
