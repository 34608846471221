import React from 'react';
import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom';
import articleImage from '../../assets/images/article_icon.svg';
import { toTimeAgo } from '../../common';
import { IContentArticleItem } from '../../store/creatorContent/types';
import FollowButton from '../FollowButton';
import SimpleCreatorCard from '../SimpleCreatorCard/SimpleCreatorCard';
import styles from './LatestArticleCard.module.css';

interface ILatestArticleCardProps {
  article?: IContentArticleItem;
  loading?: boolean;
}

const LatestArticleCard = ({
  loading,
  article,
}: ILatestArticleCardProps) => {
  const {
    id,
    creator,
    content,
    publishedat,
  } = article || {};
  const {
    title,
    image,
  } = content || {};
  const linkTo = `/article/${id}`;
  const formattedTime = React.useMemo(() => (publishedat ? toTimeAgo(publishedat) : ''), [publishedat]);
  return (
    <div className={[styles.main, loading && styles.loading].join(' ')}>
      <div className={styles.row}>
        <Link to={linkTo} className={styles.video}>
          <div className={styles.articleImageWrapper}>
            <div
              className={styles.articleImage}
              style={{
                backgroundImage: image && `url(${image})`,
              }}
            />
            <img className={styles.articleIcon} src={articleImage} alt="article icon" />
          </div>
        </Link>
        <SimpleCreatorCard className={styles.showCard} loading={loading} {...creator!} />
      </div>
      {creator && (
        <div className={styles.mobileNameRow}>
          <Link className={styles.showName} to={`/${creator.type}/${creator.profile_key}`}>{creator.displayname}</Link>
          <MediaQuery maxWidth={849}>
            <div className={styles.followButton}>
              <FollowButton useLinkStyle slug={creator.profile_key} followType="creator" />
            </div>
          </MediaQuery>
        </div>)}
      <Link to={linkTo} className={styles.title}>{!loading && title}</Link>
      {(publishedat || loading) && <span className={styles.timeAgo}>{formattedTime}</span>}
    </div>
  );
};

export default LatestArticleCard;
