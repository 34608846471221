import React from 'react';
import { connect } from 'react-redux';
import Header from './Header';

import { RouteComponentProps, withRouter } from 'react-router';
import { AppState } from '../../store';
import { IUser } from '../../store/user/types';

const {
  REACT_APP_ENABLE_SEARCH,
} = process.env;

export interface IHeaderControlProps extends RouteComponentProps {
  user?: IUser | null;
}

const HeaderControl = (props: IHeaderControlProps) => (
  <Header {...props} searchenabled={REACT_APP_ENABLE_SEARCH === 'true'} />
);

const mapStateToProps = ({ user }: AppState) => ({
  user: user.data,
});

export default withRouter(connect(mapStateToProps)(HeaderControl));
