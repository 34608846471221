import React from 'react';
import ReactJWPlayer from 'react-jw-player';

interface IJWPlayerProps {
  videoId?: string;
  /**
   * This is to be used when displaying multiple videos in a non-tabbed layout due to how JWPlayer works.
   *
   * When in a tabbed layout use the default or set to false so when switching between the tabs, the video
   *   gets changed out.
   */
  useUnique?: boolean;
  autoPlay?: boolean;
}

const JWPlayer = ({ videoId, useUnique = false, autoPlay = false }: IJWPlayerProps) => {
  return (
    <ReactJWPlayer
      playerId={`JWPlayer${useUnique ? `-${videoId}` : ''}`}
      playerScript="https://cdn.jwplayer.com/libraries/WZ7FDZjs.js"
      playlist={`https://cdn.jwplayer.com/v2/media/${videoId}`}
      customProps={{
        autostart: autoPlay ? 'viewable' : false,
      }}
    />
  );
};

export default JWPlayer;
