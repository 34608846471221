import React from 'react';
import ReactDOM from 'react-dom';

import styles from './HeroSection.module.css';

interface IYoutubeIframeProps extends React.IframeHTMLAttributes<HTMLIFrameElement> {
  videoId: string;
  autoplay?: '0' | '1';
  mute?: '0' | '1';
  controls?: '0' | '1';
}

export const YoutubeIframe = ({
  videoId,
  autoplay = '1',
  mute = '1',
  controls = '1',
  ...props
}: IYoutubeIframeProps) => {
  const iframeUrl = `https://www.youtube.com/embed/${videoId}?`
    + `rel=0&autoplay=${autoplay}&mute=${mute}&loop=1&controls=${controls}&modestbranding=1&iv_load_policy=3`
    + `&playlist=${videoId}`;
  return (
    <iframe
      title="Background Video"
      src={iframeUrl}
      frameBorder={0}
      allowFullScreen
      width="100%"
      height="100%"
      {...props}
    />
  );
};

export interface IHeroSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  bgYoutubeVideoId?: string;
  bgVideoUrl?: string;
  bgImageUrl?: string;
  bgColor?: string;
  showBgOverlay?: boolean;
}

export const HeroSection = ({
  bgYoutubeVideoId,
  bgVideoUrl,
  bgImageUrl,
  bgColor,
  className,
  children,
  showBgOverlay = true,
  ...props}: IHeroSectionProps) => {
  return (
    <div
      role="banner"
      className={[styles.main, className].join(' ')}
      {...props}
    >
      <div
        className={styles.bg}
        style={{
          backgroundColor: bgColor ? bgColor : undefined,
          backgroundImage: bgImageUrl ? `url(${bgImageUrl})` : undefined,
        }}
      >
        {bgVideoUrl && (
          <video
            className={styles.bgContents}
            autoPlay
            loop
            muted
            playsInline
            src={bgVideoUrl}
          />
        )}
        {bgYoutubeVideoId && (
          <YoutubeIframe
            className={styles.bgContents}
            videoId={bgYoutubeVideoId}
          />
        )}
      </div>
      {showBgOverlay && <div className={styles.bgOverlay} />}
      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
};

interface IPortaledHeroSectionProps extends IHeroSectionProps {
  portalId?: string;
}

const PortaledHeroSection = ({ portalId = 'hero', ...props }: IPortaledHeroSectionProps) => {
  const portalDestination = document.getElementById(portalId);
  if (portalDestination) {
    // Use createPortal to render HeroSection outside of the
    // "main" element so it can use full width of page
    return ReactDOM.createPortal(
      <HeroSection {...props} />,
      portalDestination,
    );
  } else {
    return (
      <HeroSection {...props} />
    );
  }
};

export default PortaledHeroSection;
