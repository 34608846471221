import get from 'lodash/get';
import {
    ACTION_VIDEOS_BY_PLAYER_ID_CLEAR,
    ACTION_VIDEOS_BY_PLAYER_ID_COMPLETE,
    ACTION_VIDEOS_BY_PLAYER_ID_FAILED,
    ACTION_VIDEOS_BY_PLAYER_ID_PROCESSING,
    IAppStateVideosByPlayerId,
    IVideoDataByPlayerId,
    VideosByPlayerIdAction,
} from './types';

const videosByPlayerId = (
  state: IAppStateVideosByPlayerId = {},
  action: VideosByPlayerIdAction,
): IAppStateVideosByPlayerId => {
  const {
    type,
    id,
    data = [],
    rowCount,
  } = action;
  const stateData = get(state, `[${id}].data`, []);
  switch (type) {
    case ACTION_VIDEOS_BY_PLAYER_ID_PROCESSING:
      return {
        ...state,
        [id]: {
          ...state[id],
          isFetching: new Date(),
        },
      };
    case ACTION_VIDEOS_BY_PLAYER_ID_COMPLETE:
      return {
        ...state,
        [id]: {
          data: [
            ...stateData as IVideoDataByPlayerId[],
            ...data,
          ],
          fetchedAt: new Date(),
          isFetching: false,
          rowCount,
        },
      };
    case ACTION_VIDEOS_BY_PLAYER_ID_CLEAR:
      return {
        ...state,
        [id]: {
          data: [],
          fetchedAt: new Date(),
          isFetching: false,
          rowCount,
        },
      };
    case ACTION_VIDEOS_BY_PLAYER_ID_FAILED:
      return {
        ...state,
        [id]: {
          isFetching: false,
        },
      };
    default:
      return state;
  }
};

export default videosByPlayerId;
