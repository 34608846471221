import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import { IUser } from '../../store/user/types';
import {
  addUserFollows as addUserFollowsAction,
  deleteUserFollows as deleteUserFollowsAction,
  getUserFollowsBySlug as getUserFollowsBySlugAction,
} from '../../store/userFollows/actions';
import { IUserFollows } from '../../store/userFollows/types';
import Button from '../Form/Button';
import ButtonLink from '../Form/ButtonLink';
import styles from './FollowButton.module.css';

interface IFollowButtonProps {
  onFollowClick?: (follows: number) => void;
  addUserFollows: (slug: string, followType: string) => Promise<void>;
  deleteUserFollows: (slug: string, followType: string) => Promise<void>;
  getUserFollowsBySlug: (slug: string, followType: string) => Promise<IUserFollows>;
  slug: string;
  followType: string;
  user?: IUser | null;
  useLinkStyle?: boolean;
}

const FollowButton = ({
  addUserFollows,
  deleteUserFollows,
  getUserFollowsBySlug,
  onFollowClick,
  slug,
  followType,
  user,
  useLinkStyle,
}: IFollowButtonProps) => {
  const [isFollowed, setIsFollowed] = React.useState(false);
  React.useEffect(() => {
    const getIsFollowed = async (s: string, t: string) => {
      const follows = await getUserFollowsBySlug(s, t);
      if (follows) {
        setIsFollowed(true);
      }
    };
    if (user) {
      getIsFollowed(slug, followType);
    }
  }, [user, getUserFollowsBySlug, slug, followType]);
  const onClick = React.useCallback(() => {
    if (!isFollowed) {
      setIsFollowed(true);
      if (onFollowClick) { onFollowClick(1); }
      addUserFollows(slug, followType);
    } else {
      setIsFollowed(false);
      if (onFollowClick) { onFollowClick(-1); }
      deleteUserFollows(slug, followType);
    }
  }, [addUserFollows, deleteUserFollows, isFollowed, followType, slug, setIsFollowed, onFollowClick]);

  const buttonText = React.useMemo(() => {
    let prefix = '';
    if (useLinkStyle) {
      prefix = isFollowed ? '- ' : '+ ';
    }
    return (isFollowed ? `${prefix}Unfollow` : `${prefix}Follow`);
  }, [isFollowed, useLinkStyle]);

  // Link to login if not already logged in
  if (!user) {
    return (
      <ButtonLink to="/sign-up" invert className={useLinkStyle ? styles.linkMain : styles.main}>
        {buttonText}
      </ButtonLink>
    );
  }
  return (
    <Button invert className={useLinkStyle ? styles.linkMain : styles.main} onClick={onClick}>
      {buttonText}
    </Button>
  );
};

const mapStateToProps = ({ user }: AppState) => ({
  user: user.data,
});

const mapDispatchToProps = {
  addUserFollows: addUserFollowsAction,
  deleteUserFollows: deleteUserFollowsAction,
  getUserFollowsBySlug: getUserFollowsBySlugAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(FollowButton);
