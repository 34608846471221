import * as Sentry from '@sentry/browser';
import React from 'react';

const {
  // Defined in Cricle CI so non-dev builds will log errors to Sentry
  // https://circleci.com/gh/WheelhouseStudios/NextOnes/edit#env-vars
  REACT_APP_SENTRY_DSN,
} = process.env;

if (REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    ignoreErrors: [
      // Error caused by Rechart ResponsiveContainer
      'ResizeObserver loop limit exceeded',
      // Error caused by navigating away before
      // react-jw-player loads
      'n.setup is not a function',
    ],
   });
}

export default class SentryErrorBoundary extends React.Component {
  public componentDidCatch(error: any, errorInfo: { [key: string]: any }) {
    if (REACT_APP_SENTRY_DSN) {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        Sentry.captureException(error);
      });
    }
  }

  public render() {
    return this.props.children;
  }
}
