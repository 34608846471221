import {
  ACTION_ORGANIZATIONS_BY_TYPE_COMPLETE,
  ACTION_ORGANIZATIONS_BY_TYPE_FAILED,
  ACTION_ORGANIZATIONS_BY_TYPE_PROCESSING,
  IAppStateOrganizationsByType,
  OrganizationsByTypeAction,
} from './types';

const organizationsByType = (
  state: IAppStateOrganizationsByType = {},
  action: OrganizationsByTypeAction,
): IAppStateOrganizationsByType => {
  const {
    type,
    orgType,
    data,
  } = action;
  switch (type) {
    case ACTION_ORGANIZATIONS_BY_TYPE_PROCESSING:
      return {
        ...state,
        [orgType]: {
          isFetching: new Date(),
        },
      };
    case ACTION_ORGANIZATIONS_BY_TYPE_COMPLETE:
      return {
        ...state,
        [orgType]: {
          data,
          fetchedAt: new Date(),
          isFetching: false,
        },
      };
    case ACTION_ORGANIZATIONS_BY_TYPE_FAILED:
      return {
        ...state,
        [orgType]: {
          isFetching: false,
        },
      };
    default:
      return state;
  }
};

export default organizationsByType;
