import React from 'react';
import { Link } from 'react-router-dom';
import { BasicPlayerData } from '../../store/players/types';
import { RoundPlayerImageWithBorder } from '../PlayerImage/PlayerImage';
import styles from './CompactPlayerCard.module.css';

interface ICompactPlayerCard {
  loading: boolean;
  player?: BasicPlayerData;
}

const CompactPlayerCard = ({
  loading,
  player,
}: ICompactPlayerCard) => {
  const {
    trending_rank,
    imageuri,
    slug,
    team_name,
    firstname,
    lastname,
  } = player || {};
  return (
    <Link to={`/player/${slug}`} className={[styles.main, loading && styles.loading].join(' ')}>
      <span className={styles.trendingPosition}>{loading ? ' ' : trending_rank}</span>
      <div className={styles.imageSection}>
        <RoundPlayerImageWithBorder src={imageuri} className={styles.playerImage} loading={loading} />
      </div>
      <div className={styles.textSection}>
        <span className={styles.name}>{!loading && `${firstname} ${lastname}`}</span>
        <span className={styles.team}>{!loading && team_name}</span>
      </div>
    </Link>
  );
};

export default CompactPlayerCard;
