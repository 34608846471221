import {
  ACTION_PLAYLISTS_COMPLETE,
  ACTION_PLAYLISTS_FAILED,
  ACTION_PLAYLISTS_PROCESSING,
  IAppStatePlaylists,
  PlaylistsAction,
} from './types';

const playlists = (
  state: IAppStatePlaylists = { data: [] },
  action: PlaylistsAction,
): IAppStatePlaylists => {
  const {
    type,
    data,
  } = action;
  switch (type) {
    case ACTION_PLAYLISTS_PROCESSING:
      return {
        ...state,
        isFetching: new Date(),
      };
    case ACTION_PLAYLISTS_COMPLETE:
      return {
        ...state,
        data,
        fetchedAt: new Date(),
        isFetching: false,
      };
    case ACTION_PLAYLISTS_FAILED:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default playlists;
