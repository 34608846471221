import { IArticle } from '../articles/types';
import { ICreator } from '../creator/types';
import { IVideoDataWithCreator } from '../videos/types';

export type IContent = IVideoDataWithCreator | IArticle;

interface IContentItem {
  id: string;
  content_type: string;
  creator?: ICreator;
  creator_id: string;
  publishedat: string;
  updatedat: string;
}

interface IContentVideo {
  description: string;
  playlistid: string;
  title: string;
  featured: boolean;
  hashtags: string;
  videoid: string;
  channeltitle: string;
  channellogo: string;
  type: string | null;
  creator_id: string;
  nextones_like_count: number;
}

interface IContentArticle {
  title?: string;
  subtitle?: string;
  body?: string;
  hashtags?: string;
  hashtags_clean?: string;
  image?: string;
  content_user_id: string;
  nextones_like_count: number;
  allow_html: boolean;
}

interface IContentUserPost {
  body?: string;
  content_user_id: string;
  nextones_like_count: number;
}

export interface IContentArticleItem extends IContentItem {
  content: IContentArticle;
}

export interface IContentVideoItem extends IContentItem {
  content: IContentVideo;
}

export interface IContentUserPostItem extends IContentItem {
  content: IContentUserPost;
}

export function instanceOfIContentArticleItem(object: any): object is IContentArticleItem {
  return 'content_type' in object && object.content_type === 'article';
}

export function instanceOfIContentVideoItem(object: any): object is IContentVideoItem {
  return 'content_type' in object && object.content_type === 'video';
}

export function instanceOfIContentUserPostItem(object: any): object is IContentArticleItem {
  return 'content_type' in object && object.content_type === 'user_post';
}
