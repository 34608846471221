import range from 'lodash/range';
import React from 'react';
import SyncLoader from 'react-spinners/SyncLoader';
import {
  IContentArticleItem,
  IContentUserPostItem,
  IContentVideoItem,
  instanceOfIContentArticleItem,
  instanceOfIContentUserPostItem,
  instanceOfIContentVideoItem,
} from '../../store/creatorContent/types';
import { IPlaylistData } from '../../store/playlists/types';
import Button from '../Form/Button';
import ButtonLink from '../Form/ButtonLink';
import LatestArticleCard from './LatestArticleCard';
import LatestShowCard from './LatestShowCard';
import styles from './LatestShowList.module.css';
import LatestUserPostCard from './LatestUserPostCard';

export interface ILatestShow {
  contentItem: IContentArticleItem | IContentVideoItem | IContentUserPostItem;
  show?: IPlaylistData;
}

interface ILatestShowList {
  title: string;
  latestShows: ILatestShow[];
  loading: boolean;
  onLoadMore?: () => void;
  showLoadMore?: boolean;
  numPlaceholdersToShowWhenLoading?: number;
}

interface IContentCard extends ILatestShow {
  loading: boolean;
}

const ContentCard = ({
  contentItem,
  show,
  loading,
  ...props
}: IContentCard) => {
  if (instanceOfIContentVideoItem(contentItem)) {
    return (
      <LatestShowCard loading={loading} show={show} video={contentItem} {...props} />
    );
  } else if (instanceOfIContentUserPostItem(contentItem)) {
    return (
      <LatestUserPostCard loading={loading} post={contentItem} {...props} />
    );
  } else if (instanceOfIContentArticleItem(contentItem)) {
    return (<LatestArticleCard loading={loading} article={contentItem} {...props} />);
  } else {
    return null;
  }
};

const LatestShowList = ({
  title,
  latestShows,
  loading,
  onLoadMore,
  showLoadMore,
  numPlaceholdersToShowWhenLoading = 10,
}: ILatestShowList) => {
  const [isLoadingMore, setIsLoadingMore] = React.useState(false);
  const showsWithLoadingPlaceholders = React.useMemo(() => {
    const r = [
      ...latestShows,
      ...range(loading ? numPlaceholdersToShowWhenLoading : 0).map(() => 'loading'),
    ];
    setIsLoadingMore(false);
    return r;
  }, [latestShows, loading, numPlaceholdersToShowWhenLoading]);
  const loadMoreFn = React.useCallback(() => {
    setIsLoadingMore(true);
    if (onLoadMore) {
      onLoadMore();
    }
  }, [onLoadMore]);
  return (
    <div className={styles.main}>
      <h3>{title}</h3>
      {
        showsWithLoadingPlaceholders.length > 0 ?
          showsWithLoadingPlaceholders.map((s, i) => (
            typeof s === 'string' ? <LatestShowCard key={`loadingShow${i}`} loading />
              : (
              <ContentCard
                loading={loading}
                key={`show${s.contentItem.id}`}
                show={s.show}
                contentItem={s.contentItem}
              />
            )))
          : (
            <div className={styles.noShows}>
              <span data-testid="Description" className={styles.smallText}>
                We're working to bring you content from the Players and Creators you care about.
                Click here to Discover more great Players to follow!
              </span>
              <ButtonLink
                to="\Discover"
              >
                Discover
              </ButtonLink>
            </div>
          )
      }
      {showLoadMore && (
        <Button className={styles.showMore} onClick={loadMoreFn} disabled={isLoadingMore}>
          {isLoadingMore ? <SyncLoader /> : 'Show More'}
        </Button>
      )}
    </div>
  );
};

export default LatestShowList;
