import { ICommon } from '../types';

export interface IHyvorGuestLogin {
  userData?: string;
  hash?: string;
  loginURL?: string;
  signupURL?: string;
}

export interface IAppStateHyvorGuestLogin extends ICommon {
  data?: IHyvorGuestLogin;
}

export const ACTION_HYVOR_GUEST_PROCESSING = 'ACTION_HYVOR_GUEST_PROCESSING';
export const ACTION_HYVOR_GUEST_COMPLETE = 'ACTION_HYVOR_GUEST_COMPLETE';
export const ACTION_HYVOR_GUEST_FAILED = 'ACTION_HYVOR_GUEST_FAILED';

interface IHyvorGuestActionProcessing {
  type: typeof ACTION_HYVOR_GUEST_PROCESSING;
  data: IHyvorGuestLogin;
}
interface IHyvorGuestActionComplete {
  type: typeof ACTION_HYVOR_GUEST_COMPLETE;
  data: IHyvorGuestLogin;
}
interface IHyvorGuestActionFailed {
  type: typeof ACTION_HYVOR_GUEST_FAILED;
  data: IHyvorGuestLogin;
}

export type PlaylistsAction =
  IHyvorGuestActionProcessing |
  IHyvorGuestActionComplete |
  IHyvorGuestActionFailed;
