import {
  ACTION_PLAYERS_COMPLETE,
  ACTION_PLAYERS_FAILED,
  ACTION_PLAYERS_PROCESSING,
  ACTION_PLAYERS_SINGLE_COMPLETE,
  ACTION_PLAYERS_SINGLE_FAILED,
  ACTION_PLAYERS_SINGLE_PROCESSING,
  BasicPlayerData,
  IAppStatePlayers,
  PlayersAction,
} from './types';

const mergePlayerData = (newData: BasicPlayerData[] = [], oldData: BasicPlayerData[] = []) => [
  // Only include oldData if it isn't in the newData (based on player id)
  ...oldData.filter((o) => !newData.find((n) => n.id === o.id)),
  ...newData,
];

const players = (
  state: IAppStatePlayers = { data: [] },
  action: PlayersAction,
): IAppStatePlayers => {
  switch (action.type) {
    case ACTION_PLAYERS_PROCESSING:
      return {
        ...state,
        isFetching: new Date(),
      };
    case ACTION_PLAYERS_COMPLETE:
      return {
        ...state,
        data: mergePlayerData(action.data, state.data),
        fetchedAt: new Date(),
        isFetching: false,
        rowCount: action.rowCount,
      };
    case ACTION_PLAYERS_FAILED:
      return {
        ...state,
        fetchedAt: new Date(),
        isFetching: false,
      };
    case ACTION_PLAYERS_SINGLE_PROCESSING:
      return {
        ...state,
        isFetching: new Date(),
      };
    case ACTION_PLAYERS_SINGLE_COMPLETE:
      return {
        ...state,
        data: mergePlayerData([action.data], state.data),
        fetchedAt: new Date(),
        isFetching: false,
      };
    case ACTION_PLAYERS_SINGLE_FAILED:
      return {
        ...state,
        fetchedAt: new Date(),
        isFetching: false,
      };
    default:
      return state;
  }
};

export default players;
