import { ICommon } from '../types';

export interface IPlayerStats {
  ast_average: number | null;
  ast_average_rating: number | null;
  ast_total: number | null;
  ast_total_rating: number | null;
  blks_average: number | null;
  blks_average_rating: number | null;
  blks_total: number | null;
  blks_total_rating: number | null;
  fg_percent_average: number | null;
  fg_percent_average_rating: number | null;
  fgm_average: number | null;
  fgm_average_rating: number | null;
  fgm_total: number | null;
  fgm_total_rating: number | null;
  ft_percent_average: number | null;
  ft_percent_average_rating: number | null;
  ftm_average: number | null;
  ftm_average_rating: number | null;
  ftm_total: number | null;
  ftm_total_rating: number | null;
  id: string;
  interval_id: number;
  performance: number | null;
  player_id: string;
  pts_average: number | null;
  pts_average_rating: number | null;
  pts_total: number | null;
  pts_total_rating: number | null;
  reb_average: number | null;
  reb_average_rating: number | null;
  reb_total: number | null;
  reb_total_rating: number | null;
  st_average: number | null;
  st_average_rating: number | null;
  st_total: number | null;
  st_total_rating: number | null;
  three_pm_average: number | null;
  three_pm_average_rating: number | null;
  three_pm_total: number | null;
  three_pm_total_rating: number | null;
  interval_name: string;
  interval_code: string;
  start_date: string;
  end_date: string;
}

export interface IAppStatePlayerStats extends ICommon {
  /**
   * slug: { year_1: {...}, year_2: {...}, etc...}
   */
  data: Record<string, Record<string, IPlayerStats>>;
  rowCount?: number;
}

export const ACTION_PLAYERS_STATS_PROCESSING = 'ACTION_PLAYERS_STATS_PROCESSING';
export const ACTION_PLAYERS_STATS_COMPLETE = 'ACTION_PLAYERS_STATS_COMPLETE';
export const ACTION_PLAYERS_STATS_FAILED = 'ACTION_PLAYERS_STATS_FAILED';

export const ACTION_PLAYERS_STATS_SINGLE_PROCESSING = 'ACTION_PLAYERS_STATS_SINGLE_PROCESSING';
export const ACTION_PLAYERS_STATS_SINGLE_COMPLETE = 'ACTION_PLAYERS_STATS_SINGLE_COMPLETE';
export const ACTION_PLAYERS_STATS_SINGLE_FAILED = 'ACTION_PLAYERS_STATS_SINGLE_FAILED';

interface IPlayersStatsProcessing {
  type: typeof ACTION_PLAYERS_STATS_PROCESSING;
}
interface IPlayersStatsComplete {
  type: typeof ACTION_PLAYERS_STATS_COMPLETE;
  rowCount: number;
  data: IPlayerStats[];
  slug: string;
}
interface IPlayersStatsFailed {
  type: typeof ACTION_PLAYERS_STATS_FAILED;
}

interface IPlayersStatsSingleProcessing {
  type: typeof ACTION_PLAYERS_STATS_SINGLE_PROCESSING;
}
interface IPlayersStatsSingleComplete {
  type: typeof ACTION_PLAYERS_STATS_SINGLE_COMPLETE;
  data: IPlayerStats;
  slug: string;
}
interface IPlayersStatsSingleFailed {
  type: typeof ACTION_PLAYERS_STATS_SINGLE_FAILED;
}

export type PlayersStatsAction =
  IPlayersStatsProcessing |
  IPlayersStatsComplete |
  IPlayersStatsFailed |
  IPlayersStatsSingleProcessing |
  IPlayersStatsSingleComplete |
  IPlayersStatsSingleFailed;
