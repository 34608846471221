import React, { Component, Suspense } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import TagManager from 'react-gtm-module';
import { connect, Provider } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import {
  applyMiddleware,
  createStore,
} from 'redux';

import * as firebase from 'firebase/app';
import 'firebase/auth';

import commonConfig from './common-config-symlink.json';

import thunkMiddleware from 'redux-thunk';
import Discover from './components/Discover';
import DocumentTitle from './components/DocumentTitle';
import Footer from './components/Footer/Footer';
import Header from './components/Header';
import Loading from './components/Loading';

import reducers from './store';

import { setSignedInUser } from './store/user/actions';
import { IUser } from './store/user/types';

import styles from './App.module.css';
import sharedStyles from './common/shared.module.css';

import './fonts/bebas.ttf';

const ArticleDetail = React.lazy(() => import('./components/ArticleDetail'));
const DraftPicks = React.lazy(() => import('./components/DraftPick'));
const Players = React.lazy(() => import('./components/Players'));
const VideoDetail = React.lazy(() => import('./components/VideoDetail'));
const SearchResults = React.lazy(() => import('./components/SearchResults'));
const ViewAllVideoResults = React.lazy(() => import('./components/ViewAllVideoResults'));
const PlayerDetail = React.lazy(() => import('./components/PlayerDetail'));
const ViewAllVideoResultsByPlayerId = React.lazy(() => import('./components/ViewAllVideoResultsByPlayerId'));
const TermsOfUse = React.lazy(() => import('./components/TermsOfUse'));
const PrivacyPolicy = React.lazy(() => import ('./components/PrivacyPolicy'));
const NoMatch = React.lazy(() => import('./components/NoMatch/NoMatch'));
const ComparePlayers = React.lazy(() => import('./components/ComparePlayers'));
const ShowPage = React.lazy(() => import('./components/ShowPage'));
const Explorer = React.lazy(() => import('./components/Explorer'));
const ExplorerForScouts = React.lazy(() => import('./components/Explorer/ExploreForScouts'));
const ShowsPage = React.lazy(() => import('./components/ShowsPage'));
const LogIn = React.lazy(() => import('./components/LogIn'));
const LogOut = React.lazy(() => import('./components/LogOut'));
const Profile = React.lazy(() => import('./components/Profile'));
const CollectInfo = React.lazy(() => import('./components/SignUp/CollectInfo/CollectInfo'));
const SetPassword = React.lazy(() => import('./components/SignUp/SetPassword/SetPassword'));
const PlayerAbout = React.lazy(() => import('./components/SignUp/PlayerAbout/PlayerAbout'));
const ForgotPassword = React.lazy(() => import('./components/SignUp/ForgotPassword/ForgotPassword'));
const AcctActions = React.lazy(() => import('./components/SignUp/AcctActions/AcctActions'));
const CreatorDetail = React.lazy(() => import('./components/CreatorDetail'));
const FanSignUp = React.lazy(() => import('./components/SignUp/FanSignUp/FanSignUp'));
const Analyze = React.lazy(() => import('./components/Analyze'));

if (process.env.NODE_ENV === 'production') {
  TagManager.initialize({
    gtmId: 'GTM-56DVSCB8',
  });
}

for (const [key, value] of Object.entries(commonConfig)) {
  window.sessionStorage.setItem(`commonConfig__${key}`, value);
}

const store = createStore(
  reducers,
  applyMiddleware(thunkMiddleware),
);

const {
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_SHOULD_UNGATE_ALL_ROUTES
} = process.env;

firebase.initializeApp({
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.NODE_ENV === 'production' ?
    `${window.location.hostname}`
    : `${REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: `${REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
});

interface IAppProps {
  setSignedInUser: (user: IUser | null) => void;
}

function PrivateRoute(routeProps: RouteProps) {
  const [user, loading] = useAuthState(firebase.auth());
  if (loading) {
    return <Loading useWhiteBg />;
  }

  // Allow routes for dev or if we override this envvar (e.g., in staging)
  if (user || process.env.NODE_ENV !== 'production' || REACT_APP_SHOULD_UNGATE_ALL_ROUTES === 'true') {
    return <Route {...routeProps} />;
  } else {
    return <Redirect to={'/sign-up'} />;
  }
}

class App extends Component<IAppProps> {
  private unregisterAuthObserver: firebase.Unsubscribe | null = null;

  public componentDidMount() {
    // Firebase loads current user asynchronously so attach a observer
    // to set/unset user on page load, sign in, sign out, etc.
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(this.props.setSignedInUser);
  }

  public componentWillUnmount() {
    if (this.unregisterAuthObserver) {
      this.unregisterAuthObserver();
    }
  }

  public render() {
    return (
      <Router>
        <>
          <Header />
          <div role="main" className={styles.heroAndMain}>
            <div id="hero" />
            <div className={`${sharedStyles.main} ${styles.main}`}>
              <Suspense fallback={<Loading useWhiteBg />}>
                <Switch>
                  {/* Private routes */}
                  <PrivateRoute exact path="/" component={Discover} />
                  <PrivateRoute exact path="/players/:league?" component={Players} />
                  <PrivateRoute path="/analyze" component={Analyze} />
                  <PrivateRoute path="/video/:videoId" component={VideoDetail} />
                  <PrivateRoute path="/article/:id" component={ArticleDetail} />
                  <PrivateRoute path="/search/:keyword" component={SearchResults} />
                  <PrivateRoute path="/view-all-video-results/:keyword" component={ViewAllVideoResults} />
                  <PrivateRoute exact path="/player/:slug/:activeTab(statistics|gear|info)?" component={PlayerDetail} />
                  <PrivateRoute exact path="/player/:slug/compare" component={ComparePlayers} />
                  <PrivateRoute
                    exact
                    path="/creator/:slug/:activeTab(articles|players|gear|about)?"
                    component={CreatorDetail}
                  />
                  <PrivateRoute
                    path="/view-all-videos-by-playerid/:playerId"
                    component={ViewAllVideoResultsByPlayerId}
                  />
                  <PrivateRoute exact path="/shows" component={ShowsPage} />
                  <PrivateRoute exact path="/show/:showId" component={ShowPage} />
                  <PrivateRoute exact path="/explorer/:playerSlug?" component={Explorer} />
                  <PrivateRoute exact path="/explorer-for-scouts/:playerSlug?" component={ExplorerForScouts} />
                  <PrivateRoute exact path="/draft" component={DraftPicks} />
                  <PrivateRoute path="/profile" component={Profile} />
                  {/* Public routes below*/}
                  <Route exact path="/login" component={LogIn} />
                  <Route exact path="/logout" component={LogOut} />
                  <Route exact path="/forgot-password" component={ForgotPassword} />
                  <Route path="/player-portal/about" component={PlayerAbout} />
                  <Route path="/player-portal/sign-up" component={CollectInfo} />
                  <Route path="/sign-up" component={FanSignUp} />
                  <Route exact path="/player-portal/set-password" component={SetPassword} />
                  <Route exact path="/acct/action" component={AcctActions} />
                  <Route path="/terms-of-use" component={TermsOfUse} />
                  <Route path="/privacy-policy" component={PrivacyPolicy} />
                  <Route component={NoMatch} />
                </Switch>
              </Suspense>
            </div>
          </div>
          <Footer />
        </>
      </Router>
    );
  }
}

const mapDispatchToProps = {
  setSignedInUser,
};

const ConnectedApp = connect(null, mapDispatchToProps)(App);

export default () => (
  <DocumentTitle>
    <Provider store={store}>
      <ConnectedApp />
    </Provider>
  </DocumentTitle>
);
