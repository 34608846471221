import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import { ICreator } from '../../store/creator/types';
import FollowButton from '../FollowButton';
import { RoundPlayerImageWithBorder } from '../PlayerImage/PlayerImage';
import fixedStyles from './FixedSizeCreatorCard.module.css';
import styles from './SimpleCreatorCard.module.css';

type simpleCardProps = 'image' | 'displayname' | 'profile_key' | 'type';

export interface ISimpleCreatorCard extends Pick<ICreator, simpleCardProps>, React.HTMLAttributes<HTMLDivElement> {
  loading?: boolean;
  responsive?: boolean;
  style?: CSSProperties;
}

const SimpleCreatorCard = ({
  className,
  image,
  loading,
  displayname,
  profile_key,
  type,
  style,
  responsive = true,
}: ISimpleCreatorCard) => {
  const chosenStyles = responsive ? styles : fixedStyles;
  return (
    <div style={style} className={[chosenStyles.main, loading && chosenStyles.loading, className].join(' ')}>
      <Link className={styles.link} to={`/${type}/${profile_key}`}>
        <RoundPlayerImageWithBorder
          className={chosenStyles.creatorImage}
          src={image}
          loading={loading}
        />
        <div className={chosenStyles.name}>
          {!loading && displayname}
        </div>
        <div className={chosenStyles.type}>{!loading && type}</div>
      </Link>
      <div className={styles.followContainer}>
        {!loading && <FollowButton useLinkStyle slug={profile_key} followType="creator" />}
      </div>
    </div>
  );
};
export default SimpleCreatorCard;
