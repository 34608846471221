import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { IVideoData } from '../../store/videosById/types';
import JWPlayer from '../JWPlayer';

import styles from './InlineVideoPlayer.module.css';

type IPlayerVideoData = Pick<IVideoData, 'videoid' | 'type' | 'title'>;

const InlineVideoPlayer = ({
  loading,
  video,
 }: {
   loading?: boolean;
   video?: IPlayerVideoData,
}) => {
  const [showPlayer, setShowPlayer] = React.useState(false);
  const playVideo = React.useCallback(() => setShowPlayer(true), []);

  let backgroundUrl;
  if (video) {
    backgroundUrl = video.type === 'youtube' ?
      `https://i.ytimg.com/vi/${video.videoid}/hqdefault.jpg` :
      `https://cdn.jwplayer.com/thumbs/${video.videoid}.jpg`;
  }

  return (
    <div className={styles.inlinePlayer}>
      {loading || !video ? (
        <div className={styles.noVideo} />
      ) : (
        <>
          <div
            className={styles.image}
            style={{
              backgroundImage: backgroundUrl && `url(${backgroundUrl})`,
              display: (video.type === 'jwplayer' && showPlayer && 'none') || 'block',
            }}
            onClick={playVideo}
          />
          {!showPlayer && (
            // On desktop imageOverlay is shown on hover so it needs also
            // needs to respond to click events
            <div className={styles.imageOverlay}>
              <div className={styles.imageOverlayInner} onClick={playVideo}>
                <FontAwesomeIcon icon={faPlay} />
              </div>
            </div>
          )}
          <>
            {showPlayer && (
              <>
                { video.type === 'jwplayer' ? (
                  <JWPlayer videoId={video.videoid} useUnique autoPlay />
                ) : (
                  <div className={styles.player}>
                    <iframe
                      title={video.title}
                      src={`https://www.youtube.com/embed/${video.videoid}?`
                        + 'rel=0&autoplay=1&controls=1&modestbranding=1&iv_load_policy=3'
                        + `&playlist=${video.videoid}&enablejsapi=1`}
                      allow="autoplay"
                      frameBorder={0}
                      allowFullScreen
                      width="100%"
                      height="100%"
                    />
                  </div>
                )}
              </>
            )}
          </>
        </>
      )}
    </div>
  );
};

export default InlineVideoPlayer;
